    export const flightTimeValues = [
        ["0030","30 minutes",30],
        ["0100","1 hour",60],
        ["0130","1:30 hours",90],
        ["0200","2 hours",120],
        ["0300","3 hours",180],
        ["0400","4 hours",240],
        ["0500","5 hours",300],
    ];

    export const enduranceValues = [
        ["0200","2 hours"],
        ["0300","3 hours"],
        ["0400","4 hours"],
        ["0500","5 hours"],
        ["0600","6 hours"],
    ];

    export const speedValues = [
        ["0060","60 Knots"],
        ["0070","70 Knots"],
        ["0080","80 Knots"],
        ["0090","90 Knots"],
        ["0100","100 Knots"],
        ["0110","110 Knots"],
        ["0120","120 Knots"],
        ["0130","130 Knots"],
        ["0140","140 Knots"],
        ["0150","150 Knots"],
        ["0160","160 Knots"],
        ["0180","180 Knots"]
    ];

    export const lsaPobValues = [
        ["001","Pilot only"],
        ["002","pilot + 1"],
    ];

    export const gaPobValues = [
        ["001","Pilot only"],
        ["002","pilot + 1"],
        ["003","pilot + 2"],
        ["004","pilot + 3"],
        ["005","pilot + 4"],
        ["006","pilot + 5"],
    ];

    export const aircraftTypeValues = [
        ["ULAC","LSA"],
        ["C152","Cessna 152"],
        ["C172","Cessna 172"],
        ["C177","Cessna 177"],
        ["C180","Cessna 180"],
        ["C182","Cessna 182"],
        ["C337","Cessna 337"],
        ["ACAM","Aircam"],
        ["PA18","Piper PA-18"],
        ["PA23","Piper PA-23"],
        ["P28A","Piper PA-28"],
        ["PAY2","Piper PA-31T"],
        ["PA32","Piper PA-32"],
        ["PAY4","Piper PA-42"],
        ["P46T","Piper PA-46"],
        ["B36T","Bonanza 36T"],
        ["BE24","Beech Sierra"],
        ["Z43","Zlin Z-143"],
        ["Z42","Zlin Z-242"],
        ["DA40","Diamond DA40"],
        ["DA42","Diamond DA42"],
        ["ST75","Stearman PT-17"],
        ["SR22","Cirrus SR-22"],
        ["R22","Robinson R22"],
        ["R44","Robinson R44"],
        ["B06","Bell 206"],
        ["B407","Bell 407"],
        ["B505","Bell 505"],
        ["B47G","Bell 47"],
        ["H269","Schweizer 300"],
        ["AS50","AS-350 AStar"],
        ["AS55","Twin Star"],
        ["S76","Sikorsky S76"],
        ["G2CA","G-2 Cabri"],
        ["GLID","Glider"],
        // ["ZZZZ","Other"],
    ];
