import axios from 'axios';
import {getLocalStorageKey, deviceInfo} from '.'

/********************************************************************/        

export function submitPlan (content, msgSuccess, msgFailed ) {

 const url = "/api/submitPlan";

 axios
   .post(url, { content })
   .then(function (response) {
     if (response.data.status === "success") {
       msgSuccess("success");
     } else {
       msgFailed({ status: "error", text: response.data.data });
     }
   })
   .catch(function (error) {
     console.log("error: ", error);
     msgFailed({ status: "error", text: error });
   });
}

/********************************************************************/        

export function submitHelpEmail (content, msgSuccess, msgFailed ) {

 const url = "/api/helpEmail";

 axios.post(url, { content })
   .then(function (response) {
     if (response.data.status === "success") {
       msgSuccess("success");
     } else {
       msgFailed({ status: "error", text: response.data.data });
     }
   })
   .catch(function (error) {
     console.log("error: ", error);
     msgFailed({ status: "error", text: error });
   });
}

/********************************************************************/      

export function getTimeCategoryExceptions(func){

 const url = "/api/getTimeExceptions";

 axios.get(url).then(function (response) {

   if(response.data  && response.data.status  && response.data.status === "success"){
       func(response.data.data)
       return;
   } else {
       func(false)
   }
 });
}

/********************************************************************/      

export function getFirStatus(func){

  const url = "/api/getFirStatus";
  axios.get(url).then(function (response) {
 
    if(response.data  && response.data.status  && response.data.status === "success"){
        func(response.data.data)
        return;
    } else {
        func(false)
    }
  });
 }
 
/********************************************************************/      

export function getOpenAirways(func){

  const url = "/api/getOpenAirways";
  axios.get(url).then(function (response) {
 
    if(response.data  && response.data.status  && response.data.status === "success"){
        func(response.data.data)
        return;
    } else {
        func(false)
    }
  });
 }
 

/********************************************************************/      

export function isPatreonPilot(licenseNum,func){

  const content = {
    licenseNum: licenseNum
  }

 const url = "/api/isPatreonPilot";

 axios.post(url, content).then(function (response) {

   if(response.data  && response.data.status  && response.data.status === "success" && response.data.data === true){
       func(true)
   } else {
       func(false) 
   }
 });

}

/********************************************************************/      
export function parseKml(){

  const content = {}

 const url = "/api/parseKml";

 axios.post(url, content).then(function (response) {

    console.log('status: ',response.data.status);
 });
}

/********************************************************************/      

export function getTrafficRules(licenseNum,func){

  const content = {
    licenseNum: licenseNum,
  }

 const url = "/api/getTrafficRules";

 axios.post(url, {content}).then(function (response) {

   if(response.data){
       func(response.data.status,response.data.data)
   } else {
       func({status: "error", data: "Error in getTrafficRules"}) 
   }
  }).catch(function (error) {
    func !== null ?? func("error", error)
  });

}

/********************************************************************/
// export function getManualRules(licenseNum,func){

//   const content = {
//     licenseNum: licenseNum,
//   }

//  const url = "/api/getManualRules";

//  axios.post(url, {content}).then(function (response) {

//    if(response.data){
//        func(response.data.status,response.data.data)
//    } else {
//        func({status: "error", data: "Error in getManualRules"}) 
//    }
//   }).catch(function (error) {
//     func !== null ?? func("error", error)
//   });

// }

/********************************************************************/

// Send error log to the database
export function logError(type, error, data, func = null) {

  const url = "/api/logError";

  var user = 'unknown';

  const picInfo = getLocalStorageKey('picInfo')

  if(picInfo && picInfo.picName){
   user = picInfo.picName;
  }

  const content = {
    user: user,
    type: type,
    error: error,
    data: data,
    deviceInfo: deviceInfo(),
  }

  axios.post(url, { content })
    .then(function (response) {
      if (response.data.status === "success") {
       func !== null ?? func("success", "success")
      } else {
       console.log('error in logError', response.data.data);
       func !== null ?? func("error", "response.data.data")
      }
    })
    .catch(function (error) {
     func !== null ?? func("error", error)
    });
}

/********************************************************************/        

export function addManualRule (notamParams, func ) {

  const url = "/api/addManualRule";
 
  axios.get(url, { params: notamParams})

    .then(function (response) {
      func(response.data.status, response.data.data)
    })
    .catch(function (error) {
      console.log("error: ", error);
      func("error", error);
    });
 }
/********************************************************************/    

export function removeManualRule (notamParams, func ) {

  const url = "/api/removeManualRule";
 
  axios.get(url, { params: notamParams})
    .then(function (response) {
      func(response.data.status, response.data.data)
    })
    .catch(function (error) {
      console.log("error: ", error);
      func("error", error);
    });
 }
/********************************************************************/        
