export const bubblesPoints = [
  {
    "name": "בועת אורן",
    "icao": "BOORN",
    "timeOpened": 12,
    "high": 2100,
    "latlng": [
      32.68339284022191,
      35.03174020386918
    ],
    "key": "BOORN",
    "route": [
      {
        "icao": "BNTOF1",
        "distance": 43,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 96,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "FRDIS",
        "distance": 70,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BARRA",
        "distance": 94,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת באר שבע",
    "icao": "BSHEV",
    "timeOpened": 14,
    "high": 1700,
    "latlng": [
      31.34089520377652,
      34.80828042815483
    ],
    "key": "BSHEV",
    "route": [
      {
        "icao": "BLHAV",
        "distance": 88,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 84,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 63,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "NEGEV",
        "distance": 150,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BMTAR",
        "distance": 87,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "NASIH",
        "distance": 57,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "SOKET",
        "distance": 52,
        "alt": 0,
        "time": 14,
        "active": true
      }
    ]
  },
  {
    "name": "בועת גוברין דרום",
    "icao": "BGOVS",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      31.75601415391891,
      35.01716770153651
    ],
    "key": "BGOVS",
    "route": [
      {
        "icao": "BGOVN",
        "distance": 64,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHARG",
        "distance": 37,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "GOVRN",
        "distance": 112,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 180,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת גוברין צפון",
    "icao": "BGOVN",
    "timeOpened": 6,
    "high": 1500,
    "latlng": [
      31.84053471965566,
      34.94169441766472
    ],
    "key": "BGOVN",
    "route": [
      {
        "icao": "AYLON",
        "distance": 17,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BGOVS",
        "distance": 62,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת גולן",
    "icao": "BRGOL",
    "timeOpened": 6,
    "high": 4000,
    "latlng": [
      33.02817858874629,
      35.69334224659494
    ],
    "key": "BRGOL",
    "route": [
      {
        "icao": "BKNRT",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHULA",
        "distance": 75,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZBTMS",
        "distance": 26,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZBTZD",
        "distance": 82,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת החולה",
    "icao": "BHULA",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      33.13075892149927,
      35.60485457291387
    ],
    "key": "BHULA",
    "route": [
      {
        "icao": "BRGOL",
        "distance": 75,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BASAN",
        "distance": 18,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZBTMS",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZKOCH",
        "distance": 19,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת זבולון",
    "icao": "BZVUL",
    "timeOpened": 0,
    "high": 500,
    "latlng": [
      32.88315887122651,
      35.13731250591986
    ],
    "key": "BZVUL",
    "route": [
      {
        "icao": "AAKKO",
        "distance": 28,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "YASIF",
        "distance": 19,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "EVLYM",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 84,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בועת זוהר",
    "icao": "BZHAR",
    "timeOpened": 12,
    "high": 2000,
    "latlng": [
      30.87355857519065,
      35.28899372665193
    ],
    "key": "BZHAR",
    "route": [
      {
        "icao": "BNGDI",
        "distance": 252,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "TZHTR",
        "distance": 206,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "HAZVA",
        "distance": 66,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 217,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת חרוד",
    "icao": "BHROD2",
    "timeOpened": 6,
    "high": 1500,
    "latlng": [
      32.57160973834363,
      35.26652692445958
    ],
    "key": "BHROD2",
    "route": [
      {
        "icao": "BMGWA1",
        "distance": 75,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 88,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 63,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 80,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BARRA",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת חרוד",
    "icao": "BHROD",
    "timeOpened": 6,
    "high": 1500,
    "latlng": [
      32.53665245141932,
      35.42211134057622
    ],
    "key": "BHROD",
    "route": [
      {
        "icao": "BMGEB",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 47,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLHF",
        "distance": 3,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZMGID",
        "distance": 119,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD1",
        "distance": 96,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "NIRYA",
        "distance": 91,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD2",
        "distance": 80,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת חרוד",
    "icao": "BHROD1",
    "timeOpened": 6,
    "high": 1500,
    "latlng": [
      32.38789688952185,
      35.49839681326457
    ],
    "key": "BHROD1",
    "route": [
      {
        "icao": "BHROD",
        "distance": 96,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 145,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNAMM1",
        "distance": 12,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת חריף",
    "icao": "BHARF",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      30.42923214925717,
      34.80038443440967
    ],
    "key": "BHARF",
    "route": [
      {
        "icao": "RUHOT",
        "distance": 139,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 136,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 193,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZNIF",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MESAR",
        "distance": 86,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת ים המלח",
    "icao": "BYMLH",
    "timeOpened": 12,
    "high": 3000,
    "latlng": [
      31.53206130948109,
      35.42987251548061
    ],
    "key": "BYMLH",
    "route": [
      {
        "icao": "ZUKIM",
        "distance": 108,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "ENGDI",
        "distance": 45,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BSHLM",
        "distance": 74,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 159,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 125,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת ירוחם",
    "icao": "BYERU",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      30.75131739746618,
      34.83340031085029
    ],
    "key": "BYERU",
    "route": [
      {
        "icao": "NEGEV",
        "distance": 180,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "YRUHM",
        "distance": 148,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "RUHOT",
        "distance": 55,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHERF",
        "distance": 193,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 200,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "HAZVA",
        "distance": 209,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZHAR",
        "distance": 217,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLEY",
        "distance": 180,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BOKER",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת כנרת",
    "icao": "BKNRT",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.84795634556891,
      35.60412704783695
    ],
    "key": "BKNRT",
    "route": [
      {
        "icao": "BRGOL",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZBTZD",
        "distance": 35,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 88,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKRML",
        "distance": 110,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 159,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת כרמיאל",
    "icao": "BKRML",
    "timeOpened": 6,
    "high": 4000,
    "latlng": [
      32.91135217186438,
      35.39929145627307
    ],
    "key": "BKRML",
    "route": [
      {
        "icao": "ZKADR",
        "distance": 37,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZETIM",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "AHIUD",
        "distance": 110,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 81,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLSO",
        "distance": 150,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKNRT",
        "distance": 110,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת להבים",
    "icao": "BLHAV",
    "timeOpened": 14,
    "high": 2000,
    "latlng": [
      31.48680631007674,
      34.82271158084971
    ],
    "key": "BLHAV",
    "route": [
      {
        "icao": "BGOVS",
        "distance": 180,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "GOVRN",
        "distance": 77,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "MKGAT",
        "distance": 66,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 40,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 88,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 82,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BDRTN",
        "distance": 106,
        "alt": 0,
        "time": 14,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מגידו א מזרח",
    "icao": "BMGEA",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.75270782305984,
      35.47192923371723
    ],
    "key": "BMGEA",
    "route": [
      {
        "icao": "ZGLNI",
        "distance": 35,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 69,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 96,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 74,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZEMACH",
        "distance": 64,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MIGDL",
        "distance": 52,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "TAVOR",
        "distance": 47,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKNRT",
        "distance": 88,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWA1",
        "distance": 74,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מגידו א מערב",
    "icao": "BMGWA1",
    "timeOpened": 0,
    "high": 2000,
    "latlng": [
      32.66035455436462,
      35.37153686483206
    ],
    "key": "BMGWA1",
    "route": [
      {
        "icao": "BMGEA",
        "distance": 74,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD2",
        "distance": 75,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מגידו א מערב",
    "icao": "BMGWA",
    "timeOpened": 0,
    "high": 2000,
    "latlng": [
      32.70116657517227,
      35.3500727014884
    ],
    "key": "BMGWA",
    "route": [
      {
        "icao": "ZGLNI",
        "distance": 66,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 58,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 65,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 84,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 66,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 77,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "KKANA",
        "distance": 33,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD2",
        "distance": 88,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מגידו ב מזרח",
    "icao": "BMGEB",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.63007137737566,
      35.49726965215496
    ],
    "key": "BMGEB",
    "route": [
      {
        "icao": "ZEMAH",
        "distance": 61,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 75,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 85,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 58,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKNRT",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD1",
        "distance": 145,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מגידו ב מערב",
    "icao": "BMGWB",
    "timeOpened": 0,
    "high": 2000,
    "latlng": [
      32.609571738568,
      35.38560878627266
    ],
    "key": "BMGWB",
    "route": [
      {
        "icao": "BMGEA",
        "distance": 96,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 47,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 58,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 57,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD2",
        "distance": 63,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AFULA",
        "distance": 53,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בועת מיתר",
    "icao": "BMTAR",
    "timeOpened": 14,
    "high": 3000,
    "latlng": [
      31.31643127367905,
      34.97462847638872
    ],
    "key": "BMTAR",
    "route": [
      {
        "icao": "BSHEV",
        "distance": 87,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "ARRAD",
        "distance": 127,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 163,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "SOKET",
        "distance": 38,
        "alt": 0,
        "time": 14,
        "active": true
      }
    ]
  },
  {
    "name": "בועת נטופה",
    "icao": "BNTOF",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.81688544375334,
      35.28472871728189
    ],
    "key": "BNTOF",
    "route": [
      {
        "icao": "AHIUD",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "EVLYM",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MOVIL",
        "distance": 43,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZGLNI",
        "distance": 66,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MIGDL",
        "distance": 114,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKRML",
        "distance": 81,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNTOF1",
        "distance": 114,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZVUL",
        "distance": 84,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "KKANA",
        "distance": 46,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BKNRT",
        "distance": 159,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "DESHE",
        "distance": 128,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת נטופה",
    "icao": "BNTOF1",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.754051688884,
      35.08644517643354
    ],
    "key": "BNTOF1",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 60,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MOVIL",
        "distance": 77,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 114,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BOORN",
        "distance": 43,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת ניצנים",
    "icao": "BNZNI",
    "timeOpened": 12,
    "high": 400,
    "latlng": [
      31.72420183610686,
      34.60981489464783
    ],
    "key": "BNZNI",
    "route": [
      {
        "icao": "BDRTN",
        "distance": 74,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "ZASHD",
        "distance": 77,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "ZBRCH",
        "distance": 36,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת נעמה",
    "icao": "BNAMM",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      31.86321245435988,
      35.3734807776074
    ],
    "key": "BNAMM",
    "route": [
      {
        "icao": "BNAMM1",
        "distance": 308,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MHOLA",
        "distance": 304,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "DUMIM",
        "distance": 44,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ALMOG",
        "distance": 59,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת נעמה",
    "icao": "BNAMM1",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      32.36832343761041,
      35.49837357278857
    ],
    "key": "BNAMM1",
    "route": [
      {
        "icao": "BNAMM",
        "distance": 308,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD1",
        "distance": 12,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת עין גדי",
    "icao": "BNGDI",
    "timeOpened": 6,
    "high": 3000,
    "latlng": [
      31.29394717651849,
      35.29095642884728
    ],
    "key": "BNGDI",
    "route": [
      {
        "icao": "BSHLM",
        "distance": 208,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ENGDI",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLMZ",
        "distance": 69,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ARRAD",
        "distance": 48,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMTAR",
        "distance": 163,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZHAR",
        "distance": 252,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYMLH",
        "distance": 189,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHALM",
        "distance": 166,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת ערה",
    "icao": "BARRA",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      32.54995018744837,
      35.12938421538101
    ],
    "key": "BARRA",
    "route": [
      {
        "icao": "BOORN",
        "distance": 94,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 70,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZMGID",
        "distance": 36,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHROD2",
        "distance": 68,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARN1",
        "distance": 100,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת פראן",
    "icao": "BFRAN",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      30.47798128843349,
      35.05461862485102
    ],
    "key": "BFRAN",
    "route": [
      {
        "icao": "RUHOT",
        "distance": 167,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 200,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BHARF",
        "distance": 136,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BZNIF",
        "distance": 174,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 182,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZOFAR",
        "distance": 82,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "MESAR",
        "distance": 58,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 113,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 123,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת צניפים",
    "icao": "BZNIF",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      30.21471558219998,
      34.90679972043503
    ],
    "key": "BZNIF",
    "route": [
      {
        "icao": "BHARF",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 174,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSTIM",
        "distance": 90,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "SIZFN",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 70,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שבטה",
    "icao": "BSVTA",
    "timeOpened": 6,
    "high": 2000,
    "latlng": [
      31.04698475673078,
      34.53366996472399
    ],
    "key": "BSVTA",
    "route": [
      {
        "icao": "AMIOZ",
        "distance": 140,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZELIM",
        "distance": 98,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "HOVAV",
        "distance": 148,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BOKER",
        "distance": 164,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLGV",
        "distance": 115,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שדרות דרום",
    "icao": "BDRTS",
    "timeOpened": 14,
    "high": 1500,
    "latlng": [
      31.42680416428798,
      34.67568585956823
    ],
    "key": "BDRTS",
    "route": [
      {
        "icao": "TZHOT",
        "distance": 104,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "NASIH",
        "distance": 59,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 45,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 84,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 82,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "BDRTN",
        "distance": 111,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "LLRU",
        "distance": 31,
        "alt": 0,
        "time": 14,
        "active": true
      },
      {
        "icao": "LLSS",
        "distance": 65,
        "alt": 0,
        "time": 14,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שדרות צפון",
    "icao": "BDRTN",
    "timeOpened": 12,
    "high": 1500,
    "latlng": [
      31.61356760393389,
      34.67708875139569
    ],
    "key": "BDRTN",
    "route": [
      {
        "icao": "BNZNI",
        "distance": 74,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "ZBRCH",
        "distance": 48,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "MACHZ",
        "distance": 80,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 111,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "MKGAT",
        "distance": 69,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 106,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שיטים",
    "icao": "BSTIM",
    "timeOpened": 6,
    "high": 2500,
    "latlng": [
      30.17258695092628,
      35.07265023425013
    ],
    "key": "BSTIM",
    "route": [
      {
        "icao": "BZNIF",
        "distance": 90,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "SIZFN",
        "distance": 81,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BFRAN",
        "distance": 182,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZZHOR",
        "distance": 67,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BMNUH",
        "distance": 83,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "YAHEL",
        "distance": 64,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "SITIM",
        "distance": 30,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שלם",
    "icao": "BSHLM",
    "timeOpened": 12,
    "high": 3000,
    "latlng": [
      31.63718860021637,
      35.34960713273965
    ],
    "key": "BSHLM",
    "route": [
      {
        "icao": "ZUKIM",
        "distance": 68,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "ENGDI",
        "distance": 104,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 208,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "BYMLH",
        "distance": 74,
        "alt": 0,
        "time": 12,
        "active": true
      },
      {
        "icao": "SHALM",
        "distance": 56,
        "alt": 0,
        "time": 12,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שרון דרום",
    "icao": "BSARS",
    "timeOpened": 0,
    "high": 700,
    "latlng": [
      32.34065305652726,
      34.94301278925036
    ],
    "key": "BSARS",
    "route": [
      {
        "icao": "BSARN",
        "distance": 110,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "GSHML",
        "distance": 62,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SHFAM",
        "distance": 99,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MEYAL",
        "distance": 81,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN1",
        "distance": 70,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SFAIM",
        "distance": 106,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AZRIL",
        "distance": 56,
        "alt": 0,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "בועת שרון צפון",
    "icao": "BSARN1",
    "timeOpened": 6,
    "high": 1000,
    "latlng": [
      32.46567489267765,
      34.93523652337576
    ],
    "key": "BSARN1",
    "route": [
      {
        "icao": "BARRA",
        "distance": 100,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 70,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 12,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ORAKV",
        "distance": 25,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "בועת שרון צפון",
    "icao": "BSARN",
    "timeOpened": 6,
    "high": 1000,
    "latlng": [
      32.51998748085261,
      34.97409310410567
    ],
    "key": "BSARN",
    "route": [
      {
        "icao": "BOORN",
        "distance": 97,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BARRA",
        "distance": 70,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 110,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "GSHML",
        "distance": 52,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 55,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "FRDIS",
        "distance": 44,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "ORAKV",
        "distance": 24,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  }
]