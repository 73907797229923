export const bubbles = [
  {
    "name": "בועת אורן",
    "icao": "BOORN",
    "timeOpened": 12,
    "low": 0,
    "high": 2100,
    "coords": [
      [
        32.626597,
        35.070655
      ],
      [
        32.588251,
        34.975712
      ],
      [
        32.645893,
        34.986751
      ],
      [
        32.712504,
        34.994868
      ],
      [
        32.735978,
        34.980415
      ],
      [
        32.76518,
        34.984248
      ],
      [
        32.744947,
        35.02942
      ],
      [
        32.750081,
        35.074818
      ],
      [
        32.722207,
        35.088116
      ],
      [
        32.681745,
        35.087624
      ],
      [
        32.657414,
        35.088894
      ],
      [
        32.633075,
        35.088254
      ],
      [
        32.626597,
        35.070655
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת באר שבע",
    "icao": "BSHEV",
    "timeOpened": 14,
    "low": 0,
    "high": 1700,
    "coords": [
      [
        31.13731,
        34.925931
      ],
      [
        31.13919599999999,
        34.892638
      ],
      [
        31.144729,
        34.888414
      ],
      [
        31.145522,
        34.864604
      ],
      [
        31.136644,
        34.828056
      ],
      [
        31.079208,
        34.849675
      ],
      [
        31.07425,
        34.841582
      ],
      [
        31.133184,
        34.809169
      ],
      [
        31.246762,
        34.803638
      ],
      [
        31.269404,
        34.759585
      ],
      [
        31.285453,
        34.766436
      ],
      [
        31.294804,
        34.764856
      ],
      [
        31.303961,
        34.761276
      ],
      [
        31.312343,
        34.752361
      ],
      [
        31.318812,
        34.741004
      ],
      [
        31.321651,
        34.729432
      ],
      [
        31.321578,
        34.716682
      ],
      [
        31.338518,
        34.70823
      ],
      [
        31.436039,
        34.761992
      ],
      [
        31.409016,
        34.814698
      ],
      [
        31.391127,
        34.838977
      ],
      [
        31.376456,
        34.858104
      ],
      [
        31.375693,
        34.85855
      ],
      [
        31.366157,
        34.867241
      ],
      [
        31.355431,
        34.884017
      ],
      [
        31.340611,
        34.907312
      ],
      [
        31.352315,
        34.933658
      ],
      [
        31.338933,
        34.931391
      ],
      [
        31.306626,
        34.900675
      ],
      [
        31.285263,
        34.927594
      ],
      [
        31.284241,
        34.930097
      ],
      [
        31.279158,
        34.935156
      ],
      [
        31.197815,
        34.906341
      ],
      [
        31.129183,
        34.928792
      ],
      [
        31.13731,
        34.925931
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת גוברין דרומית",
    "icao": "BGOVS",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        31.670895,
        34.9889
      ],
      [
        31.615147,
        34.955669
      ],
      [
        31.622376,
        34.908372
      ],
      [
        31.60344,
        34.865567
      ],
      [
        31.602293,
        34.864007
      ],
      [
        31.596904,
        34.827885
      ],
      [
        31.598235,
        34.823645
      ],
      [
        31.626491,
        34.826715
      ],
      [
        31.673819,
        34.817253
      ],
      [
        31.706291,
        34.832373
      ],
      [
        31.720988,
        34.831453
      ],
      [
        31.743897,
        34.833198
      ],
      [
        31.738028,
        34.875201
      ],
      [
        31.694554,
        34.945585
      ],
      [
        31.725101,
        34.957404
      ],
      [
        31.757174,
        34.969676
      ],
      [
        31.775109,
        34.943303
      ],
      [
        31.797925,
        34.907396
      ],
      [
        31.81155100000001,
        34.912433
      ],
      [
        31.822806,
        34.933143
      ],
      [
        31.824363,
        34.956688
      ],
      [
        31.833913,
        34.978595
      ],
      [
        31.804443,
        35.044809
      ],
      [
        31.787746,
        35.081111
      ],
      [
        31.77614,
        35.097613
      ],
      [
        31.764835,
        35.113768
      ],
      [
        31.760749,
        35.118694
      ],
      [
        31.734434,
        35.084934
      ],
      [
        31.730047,
        35.078676
      ],
      [
        31.727332,
        35.07243
      ],
      [
        31.71981600000001,
        35.057606
      ],
      [
        31.715197,
        35.048081
      ],
      [
        31.709097,
        35.037828
      ],
      [
        31.698578,
        35.02399
      ],
      [
        31.691289,
        35.013366
      ],
      [
        31.682236,
        35.00096
      ],
      [
        31.676622,
        34.991801
      ],
      [
        31.675667,
        34.991578
      ],
      [
        31.670895,
        34.9889
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת גוברין צפונית",
    "icao": "BGOVN",
    "timeOpened": 6,
    "low": 0,
    "high": 1500,
    "coords": [
      [
        31.817851,
        34.913545
      ],
      [
        31.834456,
        34.910846
      ],
      [
        31.83522,
        34.910845
      ],
      [
        31.86025,
        34.957118
      ],
      [
        31.836585,
        34.975911
      ],
      [
        31.828561,
        34.952661
      ],
      [
        31.82626,
        34.929858
      ],
      [
        31.824157,
        34.925164
      ],
      [
        31.817851,
        34.913545
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת החולה",
    "icao": "BHULA",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        33.053265,
        35.638226
      ],
      [
        33.071049,
        35.57648
      ],
      [
        33.180516,
        35.567886
      ],
      [
        33.177031,
        35.578757
      ],
      [
        33.175701,
        35.617327
      ],
      [
        33.197267,
        35.631392
      ],
      [
        33.218733,
        35.6367
      ],
      [
        33.227035,
        35.65754
      ],
      [
        33.202062,
        35.662556
      ],
      [
        33.182682,
        35.661248
      ],
      [
        33.158017,
        35.65185
      ],
      [
        33.126468,
        35.652342
      ],
      [
        33.098664,
        35.647903
      ],
      [
        33.080633,
        35.640158
      ],
      [
        33.0546,
        35.63794
      ],
      [
        33.053265,
        35.638226
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת השרון דרום",
    "icao": "BSARS",
    "timeOpened": 0,
    "low": 0,
    "high": 700,
    "coords": [
      [
        32.226307,
        34.829286
      ],
      [
        32.261066,
        34.837468
      ],
      [
        32.266743,
        34.840297
      ],
      [
        32.268589,
        34.84249
      ],
      [
        32.269749,
        34.847074
      ],
      [
        32.270516,
        34.850173
      ],
      [
        32.272283,
        34.853754
      ],
      [
        32.277677,
        34.856492
      ],
      [
        32.285389,
        34.859366
      ],
      [
        32.291811,
        34.861007
      ],
      [
        32.303165,
        34.863656
      ],
      [
        32.311915,
        34.865716
      ],
      [
        32.314474,
        34.865919
      ],
      [
        32.319842,
        34.86621
      ],
      [
        32.327262,
        34.868541
      ],
      [
        32.344823,
        34.876504
      ],
      [
        32.348913,
        34.87807
      ],
      [
        32.352043,
        34.878324
      ],
      [
        32.354564,
        34.876699
      ],
      [
        32.357849,
        34.872203
      ],
      [
        32.359189,
        34.869767
      ],
      [
        32.360178,
        34.868037
      ],
      [
        32.361245,
        34.866357
      ],
      [
        32.363559,
        34.863646
      ],
      [
        32.365041,
        34.86282
      ],
      [
        32.366488,
        34.862636
      ],
      [
        32.371686,
        34.863795
      ],
      [
        32.374939,
        34.864599
      ],
      [
        32.380215,
        34.865131
      ],
      [
        32.382207,
        34.865566
      ],
      [
        32.384216,
        34.866233
      ],
      [
        32.386406,
        34.867385
      ],
      [
        32.390566,
        34.86844
      ],
      [
        32.392522,
        34.869276
      ],
      [
        32.396852,
        34.87312
      ],
      [
        32.402964,
        34.878237
      ],
      [
        32.408413,
        34.882469
      ],
      [
        32.419624,
        34.885491
      ],
      [
        32.428014,
        34.887286
      ],
      [
        32.435043,
        34.889928
      ],
      [
        32.437506,
        34.900042
      ],
      [
        32.455506,
        34.929527
      ],
      [
        32.45567,
        34.936588
      ],
      [
        32.455639,
        34.940385
      ],
      [
        32.45074,
        34.949279
      ],
      [
        32.435378,
        34.957372
      ],
      [
        32.409199,
        34.971504
      ],
      [
        32.401012,
        35.013109
      ],
      [
        32.35864,
        35.00928
      ],
      [
        32.336881,
        34.997812
      ],
      [
        32.292025,
        34.99287
      ],
      [
        32.22808,
        34.971542
      ],
      [
        32.210516,
        34.958302
      ],
      [
        32.218512,
        34.917434
      ],
      [
        32.220509,
        34.831777
      ],
      [
        32.226307,
        34.829286
      ],
      [
        32.226307,
        34.829286
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת השרון צפון",
    "icao": "BSARN",
    "timeOpened": 6,
    "low": 0,
    "high": 1000,
    "coords": [
      [
        32.614009,
        35.05283
      ],
      [
        32.529743,
        35.098234
      ],
      [
        32.496882,
        35.03754
      ],
      [
        32.484404,
        35.023943
      ],
      [
        32.476239,
        34.975494
      ],
      [
        32.463319,
        34.959679
      ],
      [
        32.455857,
        34.955635
      ],
      [
        32.45074,
        34.949279
      ],
      [
        32.455639,
        34.940385
      ],
      [
        32.455659,
        34.936127
      ],
      [
        32.455506,
        34.929527
      ],
      [
        32.454901,
        34.895155
      ],
      [
        32.471214,
        34.904928
      ],
      [
        32.489742,
        34.892556
      ],
      [
        32.527688,
        34.902234
      ],
      [
        32.610494,
        34.922303
      ],
      [
        32.605803,
        34.951129
      ],
      [
        32.590533,
        34.947756
      ],
      [
        32.57756,
        34.965571
      ],
      [
        32.59875,
        35.006148
      ],
      [
        32.613672,
        35.056296
      ],
      [
        32.614009,
        35.05283
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת זבולון",
    "icao": "BZVUL",
    "timeOpened": 0,
    "low": 0,
    "high": 500,
    "coords": [
      [
        32.913625,
        35.103012
      ],
      [
        32.908404,
        35.166357
      ],
      [
        32.90256,
        35.169226
      ],
      [
        32.897885,
        35.169685
      ],
      [
        32.895258,
        35.172673
      ],
      [
        32.892568,
        35.179284
      ],
      [
        32.87176,
        35.172671
      ],
      [
        32.86579,
        35.172257
      ],
      [
        32.823685,
        35.149472
      ],
      [
        32.83287,
        35.128238
      ],
      [
        32.841455,
        35.131867
      ],
      [
        32.845489,
        35.103611
      ],
      [
        32.909428,
        35.100745
      ],
      [
        32.913625,
        35.103012
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת זוהר",
    "icao": "BZHAR",
    "timeOpened": 12,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        30.80768,
        35.279971
      ],
      [
        30.806943,
        35.279407
      ],
      [
        30.821215,
        35.202025
      ],
      [
        31.004734,
        35.277323
      ],
      [
        31.043194,
        35.233312
      ],
      [
        31.048185,
        35.217581
      ],
      [
        31.14364,
        35.217132
      ],
      [
        31.151996,
        35.238527
      ],
      [
        31.176691,
        35.246037
      ],
      [
        31.203151,
        35.245474
      ],
      [
        31.129787,
        35.352252
      ],
      [
        31.111818,
        35.374925
      ],
      [
        31.063985,
        35.384117
      ],
      [
        30.983,
        35.360201
      ],
      [
        30.95035,
        35.353637
      ],
      [
        30.918984,
        35.330984
      ],
      [
        30.883643,
        35.308251
      ],
      [
        30.850928,
        35.305165
      ],
      [
        30.818904,
        35.308862
      ],
      [
        30.80768,
        35.279971
      ],
      [
        30.80768,
        35.279971
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת חרוד",
    "icao": "BHROD",
    "timeOpened": 6,
    "low": 0,
    "high": 1500,
    "coords": [
      [
        32.568949,
        35.234248
      ],
      [
        32.568156,
        35.249573
      ],
      [
        32.56891,
        35.249968
      ],
      [
        32.576056,
        35.267782
      ],
      [
        32.583453,
        35.271599
      ],
      [
        32.595211,
        35.272194
      ],
      [
        32.600223,
        35.291218
      ],
      [
        32.599367,
        35.291597
      ],
      [
        32.589385,
        35.307542
      ],
      [
        32.566833,
        35.326878
      ],
      [
        32.554937,
        35.350272
      ],
      [
        32.546396,
        35.398732
      ],
      [
        32.542936,
        35.41886
      ],
      [
        32.50565,
        35.506478
      ],
      [
        32.49161,
        35.552032
      ],
      [
        32.361139,
        35.533248
      ],
      [
        32.414549,
        35.443108
      ],
      [
        32.467032,
        35.438066
      ],
      [
        32.517598,
        35.41196
      ],
      [
        32.53602,
        35.357634
      ],
      [
        32.530707,
        35.268394
      ],
      [
        32.562257,
        35.240316
      ],
      [
        32.568949,
        35.234248
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת חריף",
    "icao": "BHARF",
    "timeOpened": 6,
    "low": 0,
    "high": 4000,
    "coords": [
      [
        30.185329,
        34.738965
      ],
      [
        30.163202,
        34.721671
      ],
      [
        30.195956,
        34.709334
      ],
      [
        30.21229,
        34.703208
      ],
      [
        30.212297,
        34.703206
      ],
      [
        30.233905,
        34.695092
      ],
      [
        30.234128,
        34.695007
      ],
      [
        30.253405,
        34.687552
      ],
      [
        30.281046,
        34.677021
      ],
      [
        30.304556,
        34.668446
      ],
      [
        30.30471,
        34.668389
      ],
      [
        30.305088,
        34.668245
      ],
      [
        30.33331,
        34.657199
      ],
      [
        30.378816,
        34.640612
      ],
      [
        30.378984,
        34.64055
      ],
      [
        30.38065,
        34.639858
      ],
      [
        30.382273,
        34.639042
      ],
      [
        30.383847,
        34.638105
      ],
      [
        30.385365,
        34.637052
      ],
      [
        30.386819,
        34.635886
      ],
      [
        30.388204,
        34.634613
      ],
      [
        30.38951399999999,
        34.633238
      ],
      [
        30.390743,
        34.631767
      ],
      [
        30.391886,
        34.630207
      ],
      [
        30.392699,
        34.628958
      ],
      [
        30.393538,
        34.6276
      ],
      [
        30.44067099999999,
        34.608353
      ],
      [
        30.502843,
        34.589955
      ],
      [
        30.506564,
        34.620587
      ],
      [
        30.566308,
        34.664749
      ],
      [
        30.592874,
        34.698596
      ],
      [
        30.610313,
        34.738523
      ],
      [
        30.638837,
        34.767783
      ],
      [
        30.649004,
        34.796465
      ],
      [
        30.620693,
        34.800721
      ],
      [
        30.619418,
        34.800721
      ],
      [
        30.609342,
        34.830982
      ],
      [
        30.596145,
        34.864481
      ],
      [
        30.581748,
        34.871198
      ],
      [
        30.560898,
        34.876292
      ],
      [
        30.550607,
        34.897482
      ],
      [
        30.52332,
        34.917517
      ],
      [
        30.505877,
        34.924457
      ],
      [
        30.476803,
        34.918424
      ],
      [
        30.435766,
        34.924895
      ],
      [
        30.39103100000001,
        34.93734
      ],
      [
        30.363966,
        34.945052
      ],
      [
        30.306738,
        34.890673
      ],
      [
        30.280347,
        34.851509
      ],
      [
        30.227268,
        34.792241
      ],
      [
        30.200573,
        34.773393
      ],
      [
        30.19914,
        34.773218
      ],
      [
        30.186094,
        34.739402
      ],
      [
        30.185329,
        34.738965
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת ים המלח",
    "icao": "BYMLH",
    "timeOpened": 12,
    "low": 0,
    "high": 3000,
    "coords": [
      [
        31.690499,
        35.508392
      ],
      [
        31.644757,
        35.490069
      ],
      [
        31.602615,
        35.478473
      ],
      [
        31.531064,
        35.469416
      ],
      [
        31.441941,
        35.462514
      ],
      [
        31.394431,
        35.456273
      ],
      [
        31.343904,
        35.43868
      ],
      [
        31.35385,
        35.433165
      ],
      [
        31.361137,
        35.423187
      ],
      [
        31.368837,
        35.403421
      ],
      [
        31.370401,
        35.391414
      ],
      [
        31.370974,
        35.391416
      ],
      [
        31.404001,
        35.390663
      ],
      [
        31.423692,
        35.381395
      ],
      [
        31.453215,
        35.402892
      ],
      [
        31.4807,
        35.4039
      ],
      [
        31.502099,
        35.3982
      ],
      [
        31.508076,
        35.378395
      ],
      [
        31.552527,
        35.387491
      ],
      [
        31.596385,
        35.402838
      ],
      [
        31.624608,
        35.411883
      ],
      [
        31.700843,
        35.447719
      ],
      [
        31.690499,
        35.508392
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת ירוחם",
    "icao": "BYERU",
    "timeOpened": 6,
    "low": 0,
    "high": 2500,
    "coords": [
      [
        30.644252,
        34.836756
      ],
      [
        30.648669,
        34.828705
      ],
      [
        30.649915,
        34.829151
      ],
      [
        30.6709,
        34.820353
      ],
      [
        30.694815,
        34.798387
      ],
      [
        30.725242,
        34.788527
      ],
      [
        30.766971,
        34.790247
      ],
      [
        30.859128,
        34.828509
      ],
      [
        30.87181,
        34.82528
      ],
      [
        30.919046,
        34.786537
      ],
      [
        30.960188,
        34.791461
      ],
      [
        30.993277,
        34.774054
      ],
      [
        31.059247,
        34.839797
      ],
      [
        30.913361,
        34.972217
      ],
      [
        30.900443,
        35.00452
      ],
      [
        30.860855,
        35.049396
      ],
      [
        30.840691,
        35.092998
      ],
      [
        30.831876,
        35.129057
      ],
      [
        30.808747,
        35.236503
      ],
      [
        30.757876,
        35.28015
      ],
      [
        30.6686,
        35.24367
      ],
      [
        30.655786,
        35.204234
      ],
      [
        30.651694,
        35.186461
      ],
      [
        30.635005,
        35.172565
      ],
      [
        30.63541,
        35.172787
      ],
      [
        30.671742,
        35.121595
      ],
      [
        30.661244,
        35.097822
      ],
      [
        30.662112,
        35.084874
      ],
      [
        30.654373,
        35.074031
      ],
      [
        30.652279,
        35.06064
      ],
      [
        30.648824,
        35.050629
      ],
      [
        30.640037,
        35.045037
      ],
      [
        30.644384,
        35.026773
      ],
      [
        30.667057,
        35.001076
      ],
      [
        30.64441800000001,
        34.975835
      ],
      [
        30.64015,
        34.960683
      ],
      [
        30.64119,
        34.954314
      ],
      [
        30.641351,
        34.948683
      ],
      [
        30.64479,
        34.947999
      ],
      [
        30.664701,
        34.960983
      ],
      [
        30.675039,
        34.966218
      ],
      [
        30.685401,
        34.965597
      ],
      [
        30.69383699999999,
        34.952797
      ],
      [
        30.693017,
        34.937373
      ],
      [
        30.684723,
        34.922859
      ],
      [
        30.673473,
        34.903184
      ],
      [
        30.67231,
        34.890688
      ],
      [
        30.663034,
        34.87285
      ],
      [
        30.648037,
        34.844028
      ],
      [
        30.644252,
        34.836756
      ],
      [
        30.644252,
        34.836756
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת כנרת",
    "icao": "BKNRT",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.728843,
        35.555045
      ],
      [
        32.756727,
        35.547724
      ],
      [
        32.777657,
        35.530318
      ],
      [
        32.786443,
        35.508677
      ],
      [
        32.796824,
        35.496611
      ],
      [
        32.804237,
        35.483752
      ],
      [
        32.815612,
        35.481597
      ],
      [
        32.845902,
        35.488093
      ],
      [
        32.874963,
        35.496326
      ],
      [
        32.909585,
        35.507098
      ],
      [
        32.897837,
        35.532595
      ],
      [
        32.891989,
        35.560609
      ],
      [
        32.891674,
        35.587752
      ],
      [
        32.896533,
        35.609275
      ],
      [
        32.908222,
        35.638088
      ],
      [
        32.886544,
        35.65782
      ],
      [
        32.878913,
        35.666074
      ],
      [
        32.859618,
        35.661963
      ],
      [
        32.841997,
        35.663202
      ],
      [
        32.824512,
        35.658089
      ],
      [
        32.807287,
        35.654751
      ],
      [
        32.78752,
        35.658752
      ],
      [
        32.766488,
        35.652126
      ],
      [
        32.743332,
        35.647205
      ],
      [
        32.726493,
        35.639651
      ],
      [
        32.705422,
        35.623097
      ],
      [
        32.695214,
        35.602938
      ],
      [
        32.693593,
        35.582388
      ],
      [
        32.698267,
        35.561878
      ],
      [
        32.699186,
        35.561606
      ],
      [
        32.712367,
        35.54807
      ],
      [
        32.728843,
        35.555045
      ],
      [
        32.728843,
        35.555045
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת כרמיאל",
    "icao": "BKRML",
    "timeOpened": 6,
    "low": 0,
    "high": 4000,
    "coords": [
      [
        32.871089,
        35.419709
      ],
      [
        32.873696,
        35.400201
      ],
      [
        32.879127,
        35.359472
      ],
      [
        32.875587,
        35.303157
      ],
      [
        32.884919,
        35.252913
      ],
      [
        32.882578,
        35.212648
      ],
      [
        32.886607,
        35.193939
      ],
      [
        32.890057,
        35.187201
      ],
      [
        32.890149,
        35.182649
      ],
      [
        32.913514,
        35.172882
      ],
      [
        32.920673,
        35.101677
      ],
      [
        32.953475,
        35.089679
      ],
      [
        32.98988,
        35.096461
      ],
      [
        32.989237,
        35.189074
      ],
      [
        32.989339,
        35.279493
      ],
      [
        32.988937,
        35.341042
      ],
      [
        32.989652,
        35.421406
      ],
      [
        32.98634,
        35.438475
      ],
      [
        32.950619,
        35.45221
      ],
      [
        32.926402,
        35.46678
      ],
      [
        32.908159,
        35.470476
      ],
      [
        32.899601,
        35.474603
      ],
      [
        32.857788,
        35.479585
      ],
      [
        32.859959,
        35.459594
      ],
      [
        32.8727,
        35.441817
      ],
      [
        32.871089,
        35.419709
      ],
      [
        32.871089,
        35.419709
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת להבים",
    "icao": "BLHAV",
    "timeOpened": 14,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        31.599066,
        34.948014
      ],
      [
        31.539603,
        34.911753
      ],
      [
        31.525759,
        34.906021
      ],
      [
        31.504647,
        34.901414
      ],
      [
        31.49933,
        34.897824
      ],
      [
        31.449478,
        34.866008
      ],
      [
        31.423668,
        34.869858
      ],
      [
        31.399459,
        34.872856
      ],
      [
        31.389935,
        34.871276
      ],
      [
        31.386078,
        34.869808
      ],
      [
        31.394591,
        34.858847
      ],
      [
        31.404549,
        34.845403
      ],
      [
        31.413752,
        34.833483
      ],
      [
        31.422912,
        34.818702
      ],
      [
        31.431514,
        34.801123
      ],
      [
        31.439131,
        34.789786
      ],
      [
        31.450674,
        34.775041
      ],
      [
        31.506186,
        34.794893
      ],
      [
        31.597453,
        34.817555
      ],
      [
        31.590432,
        34.830976
      ],
      [
        31.585595,
        34.842991
      ],
      [
        31.579606,
        34.862852
      ],
      [
        31.595741,
        34.893449
      ],
      [
        31.600642,
        34.91232
      ],
      [
        31.599066,
        34.948014
      ],
      [
        31.599066,
        34.948014
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת מגידו א' מזרח",
    "icao": "BMGEA",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.776657,
        35.436913
      ],
      [
        32.789444,
        35.483333
      ],
      [
        32.773611,
        35.504167
      ],
      [
        32.763889,
        35.528611
      ],
      [
        32.744167,
        35.539722
      ],
      [
        32.722912,
        35.549088
      ],
      [
        32.712267,
        35.505653
      ],
      [
        32.719837,
        35.436904
      ],
      [
        32.700121,
        35.420229
      ],
      [
        32.70898,
        35.416645
      ],
      [
        32.756391,
        35.422409
      ],
      [
        32.761519,
        35.435724
      ],
      [
        32.776657,
        35.436913
      ],
      [
        32.776657,
        35.436913
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת מגידו א' מערב",
    "icao": "BMGWA",
    "timeOpened": 0,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.616528,
        35.290691
      ],
      [
        32.642864,
        35.293408
      ],
      [
        32.705871,
        35.301288
      ],
      [
        32.738036,
        35.326372
      ],
      [
        32.759722,
        35.346389
      ],
      [
        32.762145,
        35.372992
      ],
      [
        32.771402,
        35.403527
      ],
      [
        32.724964,
        35.406092
      ],
      [
        32.70721,
        35.406717
      ],
      [
        32.686714,
        35.414149
      ],
      [
        32.67768,
        35.402297
      ],
      [
        32.651854,
        35.364642
      ],
      [
        32.63246,
        35.323699
      ],
      [
        32.616528,
        35.290691
      ],
      [
        32.616528,
        35.290691
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת מגידו ב' מזרח",
    "icao": "BMGEB",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.690352,
        35.423512
      ],
      [
        32.715299,
        35.444344
      ],
      [
        32.708207,
        35.478109
      ],
      [
        32.702644,
        35.505499
      ],
      [
        32.712862,
        35.547512
      ],
      [
        32.69417,
        35.557701
      ],
      [
        32.684534,
        35.583964
      ],
      [
        32.658718,
        35.571031
      ],
      [
        32.646974,
        35.552054
      ],
      [
        32.613056,
        35.549657
      ],
      [
        32.563953,
        35.548267
      ],
      [
        32.534451,
        35.537408
      ],
      [
        32.499058,
        35.547866
      ],
      [
        32.529939,
        35.466943
      ],
      [
        32.54754,
        35.420959
      ],
      [
        32.587788,
        35.44318
      ],
      [
        32.640633,
        35.451163
      ],
      [
        32.667661,
        35.435442
      ],
      [
        32.684141,
        35.422293
      ],
      [
        32.690352,
        35.423512
      ],
      [
        32.690352,
        35.423512
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת מגידו ב' מערב",
    "icao": "BMGWB",
    "timeOpened": 0,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.630292,
        35.342482
      ],
      [
        32.641955,
        35.367103
      ],
      [
        32.668855,
        35.407214
      ],
      [
        32.675585,
        35.416045
      ],
      [
        32.658161,
        35.429672
      ],
      [
        32.637858,
        35.441673
      ],
      [
        32.590245,
        35.434109
      ],
      [
        32.549586,
        35.411763
      ],
      [
        32.555833,
        35.383333
      ],
      [
        32.560278,
        35.354167
      ],
      [
        32.572222,
        35.331667
      ],
      [
        32.590333,
        35.317047
      ],
      [
        32.630292,
        35.342482
      ],
      [
        32.630292,
        35.342482
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת מיתר",
    "icao": "BMTAR",
    "timeOpened": 14,
    "low": 0,
    "high": 3000,
    "coords": [
      [
        31.254444,
        35.209722
      ],
      [
        31.234494,
        35.129243
      ],
      [
        31.283842,
        35.132436
      ],
      [
        31.283814,
        34.941069
      ],
      [
        31.308762,
        34.907562
      ],
      [
        31.340251,
        34.938926
      ],
      [
        31.354482,
        34.938839
      ],
      [
        31.367097,
        34.969019
      ],
      [
        31.345431,
        35.069856
      ],
      [
        31.361894,
        35.096837
      ],
      [
        31.254444,
        35.209722
      ],
      [
        31.254444,
        35.209722
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת נטופה",
    "icao": "BNTOF",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.764389,
        35.234532
      ],
      [
        32.751903,
        35.091887
      ],
      [
        32.738653,
        35.090625
      ],
      [
        32.755111,
        35.068167
      ],
      [
        32.766712,
        35.096041
      ],
      [
        32.776682,
        35.124834
      ],
      [
        32.802878,
        35.144979
      ],
      [
        32.818967,
        35.152345
      ],
      [
        32.848686,
        35.164023
      ],
      [
        32.871184,
        35.17658
      ],
      [
        32.890149,
        35.182649
      ],
      [
        32.890587,
        35.186167
      ],
      [
        32.886607,
        35.193939
      ],
      [
        32.882578,
        35.212648
      ],
      [
        32.884919,
        35.252913
      ],
      [
        32.875586,
        35.303157
      ],
      [
        32.879127,
        35.359472
      ],
      [
        32.871089,
        35.419709
      ],
      [
        32.8727,
        35.441817
      ],
      [
        32.864619,
        35.455504
      ],
      [
        32.857788,
        35.479585
      ],
      [
        32.848137,
        35.478421
      ],
      [
        32.837465,
        35.477571
      ],
      [
        32.808618,
        35.474101
      ],
      [
        32.793257,
        35.468398
      ],
      [
        32.765868,
        35.338898
      ],
      [
        32.715576,
        35.302134
      ],
      [
        32.764389,
        35.234532
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת ניצנים",
    "icao": "BNZNI",
    "timeOpened": 12,
    "low": 0,
    "high": 400,
    "coords": [
      [
        31.685951,
        34.60962
      ],
      [
        31.668379,
        34.606123
      ],
      [
        31.67214999999999,
        34.591375
      ],
      [
        31.686449,
        34.585955
      ],
      [
        31.707798,
        34.576706
      ],
      [
        31.826165,
        34.644803
      ],
      [
        31.82547,
        34.670292
      ],
      [
        31.824769,
        34.695335
      ],
      [
        31.81446,
        34.694698
      ],
      [
        31.796103,
        34.681795
      ],
      [
        31.786913,
        34.670876
      ],
      [
        31.775441,
        34.663766
      ],
      [
        31.759403,
        34.662484
      ],
      [
        31.751562,
        34.657151
      ],
      [
        31.748291,
        34.647334
      ],
      [
        31.722666,
        34.631352
      ],
      [
        31.685951,
        34.60962
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת נעמה",
    "icao": "BNAMM",
    "timeOpened": 6,
    "low": 0,
    "high": 2500,
    "coords": [
      [
        31.89259,
        35.473255
      ],
      [
        31.894127,
        35.470578
      ],
      [
        31.912642,
        35.470672
      ],
      [
        31.912873,
        35.403539
      ],
      [
        31.818381,
        35.404916
      ],
      [
        31.826686,
        35.373429
      ],
      [
        31.814978,
        35.327108
      ],
      [
        31.822932,
        35.273257
      ],
      [
        31.914212,
        35.331753
      ],
      [
        32.063581,
        35.389678
      ],
      [
        32.158688,
        35.428527
      ],
      [
        32.227917,
        35.456059
      ],
      [
        32.404567,
        35.449393
      ],
      [
        32.353708,
        35.536633
      ],
      [
        32.312483,
        35.535483
      ],
      [
        32.274623,
        35.547995
      ],
      [
        32.243632,
        35.550697
      ],
      [
        32.208542,
        35.54615
      ],
      [
        32.174878,
        35.530948
      ],
      [
        32.112252,
        35.507835
      ],
      [
        32.046928,
        35.498217
      ],
      [
        31.967693,
        35.500285
      ],
      [
        31.886352,
        35.507004
      ],
      [
        31.89259,
        35.473255
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת עין גדי",
    "icao": "BNGDI",
    "timeOpened": 6,
    "low": 0,
    "high": 3000,
    "coords": [
      [
        31.485936,
        35.389277
      ],
      [
        31.481271,
        35.384353
      ],
      [
        31.456345,
        35.346819
      ],
      [
        31.456228,
        35.34664
      ],
      [
        31.456165,
        35.34716
      ],
      [
        31.456174,
        35.347697
      ],
      [
        31.45615,
        35.348393
      ],
      [
        31.456114,
        35.349062
      ],
      [
        31.456009,
        35.349611
      ],
      [
        31.455814,
        35.350012
      ],
      [
        31.455549,
        35.350118
      ],
      [
        31.455274,
        35.350037
      ],
      [
        31.454965,
        35.349916
      ],
      [
        31.454471,
        35.349914
      ],
      [
        31.454228,
        35.350342
      ],
      [
        31.454353,
        35.351079
      ],
      [
        31.454788,
        35.352084
      ],
      [
        31.455051,
        35.352714
      ],
      [
        31.455279,
        35.353438
      ],
      [
        31.455369,
        35.354348
      ],
      [
        31.455345,
        35.355004
      ],
      [
        31.45516,
        35.355673
      ],
      [
        31.454906,
        35.356368
      ],
      [
        31.454376,
        35.357036
      ],
      [
        31.453882,
        35.357383
      ],
      [
        31.453424,
        35.35773
      ],
      [
        31.452974,
        35.358183
      ],
      [
        31.452283,
        35.35881
      ],
      [
        31.451581,
        35.359598
      ],
      [
        31.451109,
        35.360266
      ],
      [
        31.450799,
        35.360707
      ],
      [
        31.450478,
        35.361399
      ],
      [
        31.450192,
        35.361666
      ],
      [
        31.449332,
        35.361821
      ],
      [
        31.448938,
        35.361876
      ],
      [
        31.448635,
        35.36218
      ],
      [
        31.44849,
        35.362714
      ],
      [
        31.448524,
        35.36345
      ],
      [
        31.448542,
        35.364372
      ],
      [
        31.448482,
        35.365251
      ],
      [
        31.448442,
        35.366194
      ],
      [
        31.448582,
        35.367018
      ],
      [
        31.449031,
        35.368139
      ],
      [
        31.449095,
        35.368653
      ],
      [
        31.448887,
        35.369236
      ],
      [
        31.448507,
        35.369561
      ],
      [
        31.447452,
        35.369643
      ],
      [
        31.446397,
        35.36952
      ],
      [
        31.445443,
        35.369324
      ],
      [
        31.444865,
        35.368948
      ],
      [
        31.444209,
        35.367598
      ],
      [
        31.443929,
        35.367109
      ],
      [
        31.443705,
        35.366977
      ],
      [
        31.443255,
        35.366947
      ],
      [
        31.442899,
        35.36692
      ],
      [
        31.442521,
        35.366637
      ],
      [
        31.442327,
        35.366208
      ],
      [
        31.442087,
        35.365712
      ],
      [
        31.441651,
        35.365497
      ],
      [
        31.441215,
        35.365469
      ],
      [
        31.440744,
        35.365574
      ],
      [
        31.440411,
        35.36576
      ],
      [
        31.440042,
        35.366228
      ],
      [
        31.439743,
        35.366776
      ],
      [
        31.439352,
        35.367149
      ],
      [
        31.43895,
        35.367188
      ],
      [
        31.438136,
        35.366984
      ],
      [
        31.437562,
        35.366835
      ],
      [
        31.436805,
        35.366579
      ],
      [
        31.436541,
        35.366484
      ],
      [
        31.436151,
        35.366429
      ],
      [
        31.435806,
        35.366575
      ],
      [
        31.43545,
        35.367096
      ],
      [
        31.434956,
        35.367269
      ],
      [
        31.434405,
        35.367066
      ],
      [
        31.433649,
        35.366502
      ],
      [
        31.432995,
        35.366058
      ],
      [
        31.432353,
        35.365815
      ],
      [
        31.431975,
        35.365693
      ],
      [
        31.431342,
        35.365814
      ],
      [
        31.431035,
        35.366102
      ],
      [
        31.430659,
        35.366344
      ],
      [
        31.430171,
        35.366277
      ],
      [
        31.429747,
        35.366048
      ],
      [
        31.429117,
        35.36551
      ],
      [
        31.428544,
        35.36488
      ],
      [
        31.42799400000001,
        35.364463
      ],
      [
        31.42770700000001,
        35.364342
      ],
      [
        31.427316,
        35.364388
      ],
      [
        31.426938,
        35.364727
      ],
      [
        31.42642,
        35.365301
      ],
      [
        31.425903,
        35.36558
      ],
      [
        31.425295,
        35.365605
      ],
      [
        31.424675,
        35.365496
      ],
      [
        31.424091,
        35.36508
      ],
      [
        31.42369,
        35.364744
      ],
      [
        31.423162,
        35.364354
      ],
      [
        31.422383,
        35.364084
      ],
      [
        31.421797,
        35.364109
      ],
      [
        31.421246,
        35.364402
      ],
      [
        31.420706,
        35.364908
      ],
      [
        31.41989,
        35.365267
      ],
      [
        31.419155,
        35.365492
      ],
      [
        31.418271,
        35.365677
      ],
      [
        31.417364,
        35.365741
      ],
      [
        31.416607,
        35.365671
      ],
      [
        31.415403,
        35.365574
      ],
      [
        31.414232,
        35.365517
      ],
      [
        31.41345,
        35.365419
      ],
      [
        31.413157,
        35.365463
      ],
      [
        31.412935,
        35.365847
      ],
      [
        31.412888,
        35.366395
      ],
      [
        31.412853,
        35.366837
      ],
      [
        31.412415,
        35.36745
      ],
      [
        31.411669,
        35.367689
      ],
      [
        31.410763,
        35.367766
      ],
      [
        31.410143,
        35.367871
      ],
      [
        31.409545,
        35.368204
      ],
      [
        31.409051,
        35.368684
      ],
      [
        31.408178,
        35.369056
      ],
      [
        31.407627,
        35.369187
      ],
      [
        31.406915,
        35.3696
      ],
      [
        31.406339,
        35.370467
      ],
      [
        31.405638,
        35.371187
      ],
      [
        31.404673,
        35.371799
      ],
      [
        31.40364,
        35.372371
      ],
      [
        31.402812,
        35.373184
      ],
      [
        31.402295,
        35.373651
      ],
      [
        31.401548,
        35.374157
      ],
      [
        31.399968,
        35.375871
      ],
      [
        31.399724,
        35.376122
      ],
      [
        31.39958,
        35.376259
      ],
      [
        31.39942599999999,
        35.376416
      ],
      [
        31.3993,
        35.376563
      ],
      [
        31.399128,
        35.376731
      ],
      [
        31.398984,
        35.37691
      ],
      [
        31.398848,
        35.377046
      ],
      [
        31.398713,
        35.377214
      ],
      [
        31.398568,
        35.377372
      ],
      [
        31.398433,
        35.37754
      ],
      [
        31.398342,
        35.377666
      ],
      [
        31.398234,
        35.377792
      ],
      [
        31.398126,
        35.37796
      ],
      [
        31.398008,
        35.37817
      ],
      [
        31.397872,
        35.378411
      ],
      [
        31.397809,
        35.378537
      ],
      [
        31.39771,
        35.378768
      ],
      [
        31.397601,
        35.379094
      ],
      [
        31.397547,
        35.379315
      ],
      [
        31.397456,
        35.37983
      ],
      [
        31.397434,
        35.380124
      ],
      [
        31.397428,
        35.380198
      ],
      [
        31.3974,
        35.380576
      ],
      [
        31.3974,
        35.380734
      ],
      [
        31.397508,
        35.380776
      ],
      [
        31.396995,
        35.382436
      ],
      [
        31.371475,
        35.382677
      ],
      [
        31.364073,
        35.382747
      ],
      [
        31.362002,
        35.374103
      ],
      [
        31.357259,
        35.363802
      ],
      [
        31.350202,
        35.355492
      ],
      [
        31.342815,
        35.350511
      ],
      [
        31.336423,
        35.348821
      ],
      [
        31.330607,
        35.347291
      ],
      [
        31.324246,
        35.348254
      ],
      [
        31.313072,
        35.353333
      ],
      [
        31.30493,
        35.358538
      ],
      [
        31.301053,
        35.364386
      ],
      [
        31.297567,
        35.370325
      ],
      [
        31.295044,
        35.378594
      ],
      [
        31.284979,
        35.374784
      ],
      [
        31.255194,
        35.36467
      ],
      [
        31.237405,
        35.358632
      ],
      [
        31.204728,
        35.356356
      ],
      [
        31.202874,
        35.356227
      ],
      [
        31.15885,
        35.363899
      ],
      [
        31.153853,
        35.346251
      ],
      [
        31.185761,
        35.297539
      ],
      [
        31.192684,
        35.288077
      ],
      [
        31.213927,
        35.259022
      ],
      [
        31.235784,
        35.251288
      ],
      [
        31.262118,
        35.218624
      ],
      [
        31.262807,
        35.217828
      ],
      [
        31.272597,
        35.206524
      ],
      [
        31.356203,
        35.109841
      ],
      [
        31.35713,
        35.109687
      ],
      [
        31.359683,
        35.113015
      ],
      [
        31.36563,
        35.120768
      ],
      [
        31.414255,
        35.184213
      ],
      [
        31.425387,
        35.221168
      ],
      [
        31.450794,
        35.248609
      ],
      [
        31.465364,
        35.264354
      ],
      [
        31.495684,
        35.300594
      ],
      [
        31.50547,
        35.355213
      ],
      [
        31.49163,
        35.38813
      ],
      [
        31.485936,
        35.389277
      ],
      [
        31.485936,
        35.389277
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת ערה",
    "icao": "BARRA",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        32.44764,
        35.050885
      ],
      [
        32.484693,
        35.031425
      ],
      [
        32.529716,
        35.112958
      ],
      [
        32.590298,
        35.074712
      ],
      [
        32.592511,
        35.088833
      ],
      [
        32.569666,
        35.185459
      ],
      [
        32.579303,
        35.210311
      ],
      [
        32.574444,
        35.221005
      ],
      [
        32.572131,
        35.229338
      ],
      [
        32.572067,
        35.229771
      ],
      [
        32.567864,
        35.233028
      ],
      [
        32.553681,
        35.188564
      ],
      [
        32.512503,
        35.139728
      ],
      [
        32.487714,
        35.141347
      ],
      [
        32.452878,
        35.052997
      ],
      [
        32.44764,
        35.050885
      ],
      [
        32.44764,
        35.050885
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת פארן",
    "icao": "BFRAN",
    "timeOpened": 6,
    "low": 0,
    "high": 2500,
    "coords": [
      [
        30.296903,
        34.988178
      ],
      [
        30.313894,
        34.974532
      ],
      [
        30.337098,
        34.968825
      ],
      [
        30.369636,
        34.972162
      ],
      [
        30.383754,
        34.962089
      ],
      [
        30.40049,
        34.954752
      ],
      [
        30.450165,
        34.946148
      ],
      [
        30.477894,
        34.937485
      ],
      [
        30.498917,
        34.954798
      ],
      [
        30.538081,
        34.937228
      ],
      [
        30.56611,
        34.922861
      ],
      [
        30.595672,
        34.907146
      ],
      [
        30.617446,
        34.876782
      ],
      [
        30.634565,
        34.848159
      ],
      [
        30.640627,
        34.84239
      ],
      [
        30.658038,
        34.873878
      ],
      [
        30.666774,
        34.891097
      ],
      [
        30.668745,
        34.904901
      ],
      [
        30.683037,
        34.927435
      ],
      [
        30.68863,
        34.938943
      ],
      [
        30.689371,
        34.951575
      ],
      [
        30.683028,
        34.96022
      ],
      [
        30.675571,
        34.96086
      ],
      [
        30.666346,
        34.957087
      ],
      [
        30.644783,
        34.943595
      ],
      [
        30.6386,
        34.944804
      ],
      [
        30.638042,
        34.95229
      ],
      [
        30.636199,
        34.960833
      ],
      [
        30.640551,
        34.977522
      ],
      [
        30.661782,
        35.000629
      ],
      [
        30.640222,
        35.023196
      ],
      [
        30.635101,
        35.049436
      ],
      [
        30.644167,
        35.055295
      ],
      [
        30.64763,
        35.064377
      ],
      [
        30.64704499999999,
        35.075419
      ],
      [
        30.655408,
        35.086794
      ],
      [
        30.654491,
        35.099676
      ],
      [
        30.665532,
        35.12039
      ],
      [
        30.630119,
        35.169887
      ],
      [
        30.619259,
        35.163937
      ],
      [
        30.604114,
        35.167351
      ],
      [
        30.588903,
        35.183271
      ],
      [
        30.586635,
        35.188693
      ],
      [
        30.581344,
        35.18727
      ],
      [
        30.553866,
        35.179889
      ],
      [
        30.485275,
        35.165688
      ],
      [
        30.44867899999999,
        35.146841
      ],
      [
        30.415582,
        35.144129
      ],
      [
        30.396489,
        35.144982
      ],
      [
        30.367068,
        35.159911
      ],
      [
        30.32929,
        35.13696
      ],
      [
        30.314656,
        35.127815
      ],
      [
        30.301959,
        35.111063
      ],
      [
        30.301057,
        35.110076
      ],
      [
        30.296499,
        35.077287
      ],
      [
        30.286394,
        35.044939
      ],
      [
        30.285065,
        35.012379
      ],
      [
        30.296903,
        34.988178
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת צניפים",
    "icao": "BZNIF",
    "timeOpened": 6,
    "low": 0,
    "high": 2500,
    "coords": [
      [
        30.069151,
        34.948348
      ],
      [
        30.043007,
        34.849136
      ],
      [
        30.021396,
        34.807982
      ],
      [
        30.067842,
        34.760226
      ],
      [
        30.076535,
        34.756574
      ],
      [
        30.076569,
        34.756559
      ],
      [
        30.096136,
        34.7483
      ],
      [
        30.096143,
        34.748297
      ],
      [
        30.126055,
        34.735655
      ],
      [
        30.149649,
        34.726775
      ],
      [
        30.163611,
        34.740447
      ],
      [
        30.175574,
        34.750787
      ],
      [
        30.185579,
        34.783887
      ],
      [
        30.217231,
        34.802192
      ],
      [
        30.239373,
        34.831736
      ],
      [
        30.263497,
        34.861539
      ],
      [
        30.298223,
        34.908891
      ],
      [
        30.322166,
        34.926473
      ],
      [
        30.332938,
        34.941902
      ],
      [
        30.321903,
        34.94422
      ],
      [
        30.308633,
        34.948902
      ],
      [
        30.297627,
        34.949318
      ],
      [
        30.288621,
        34.963083
      ],
      [
        30.278591,
        34.971755
      ],
      [
        30.272293,
        34.988104
      ],
      [
        30.265327,
        35.012041
      ],
      [
        30.202872,
        34.999202
      ],
      [
        30.141375,
        35.014014
      ],
      [
        30.085847,
        34.995448
      ],
      [
        30.055716,
        35.002725
      ],
      [
        30.069151,
        34.948348
      ],
      [
        30.069151,
        34.948348
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת רמת הגולן",
    "icao": "BRGOL",
    "timeOpened": 6,
    "low": 0,
    "high": 4000,
    "coords": [
      [
        32.918723,
        35.641623
      ],
      [
        32.888026,
        35.678176
      ],
      [
        32.801927,
        35.664996
      ],
      [
        32.819446,
        35.682471
      ],
      [
        32.831858,
        35.71534
      ],
      [
        32.817274,
        35.742442
      ],
      [
        32.87495348031128,
        35.78448391511172
      ],
      [
        32.9856166186204,
        35.75095246165502
      ],
      [
        33.05474007663083,
        35.73346005670785
      ],
      [
        33.13857584327921,
        35.73379022198545
      ],
      [
        33.17447914513953,
        35.73361897248221
      ],
      [
        33.19911636047699,
        35.73392529400298
      ],
      [
        33.20628181725613,
        35.7337853985954
      ],
      [
        33.22580391996682,
        35.73386050653465
      ],
      [
        33.225852,
        35.712347
      ],
      [
        33.232554,
        35.682966
      ],
      [
        33.084339,
        35.665485
      ],
      [
        33.021995,
        35.665231
      ],
      [
        32.985374,
        35.648561
      ],
      [
        32.918723,
        35.641623
      ],
      [
        32.918723,
        35.641623
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת שבטה",
    "icao": "BSVTA",
    "timeOpened": 6,
    "low": 0,
    "high": 2000,
    "coords": [
      [
        31.208325,
        34.406644
      ],
      [
        31.20306,
        34.423696
      ],
      [
        31.201687,
        34.426136
      ],
      [
        31.199148,
        34.427961
      ],
      [
        31.196062,
        34.430624
      ],
      [
        31.193334,
        34.433352
      ],
      [
        31.19092,
        34.43594
      ],
      [
        31.189252,
        34.43909
      ],
      [
        31.187751,
        34.442537
      ],
      [
        31.18667,
        34.446376
      ],
      [
        31.185739,
        34.450304
      ],
      [
        31.185312,
        34.453846
      ],
      [
        31.18506,
        34.456624
      ],
      [
        31.185093,
        34.462314
      ],
      [
        31.185209,
        34.465977
      ],
      [
        31.185424,
        34.470194
      ],
      [
        31.18611900000001,
        34.474852
      ],
      [
        31.18719,
        34.478563
      ],
      [
        31.18884,
        34.48338
      ],
      [
        31.190675,
        34.487252
      ],
      [
        31.192892,
        34.491122
      ],
      [
        31.195916,
        34.494448
      ],
      [
        31.199156,
        34.520879
      ],
      [
        31.172841,
        34.564579
      ],
      [
        31.11811,
        34.634025
      ],
      [
        31.092626,
        34.67285
      ],
      [
        31.127244,
        34.796783
      ],
      [
        31.069585,
        34.826813
      ],
      [
        30.994764,
        34.752589
      ],
      [
        30.947614,
        34.76738
      ],
      [
        30.903411,
        34.76895
      ],
      [
        30.885834,
        34.776967
      ],
      [
        30.864975,
        34.764953
      ],
      [
        30.863733,
        34.764808
      ],
      [
        30.867801,
        34.62714
      ],
      [
        30.862391,
        34.560344
      ],
      [
        30.908252,
        34.423076
      ],
      [
        31.069894,
        34.353587
      ],
      [
        31.089012,
        34.346846
      ],
      [
        31.089187,
        34.346783
      ],
      [
        31.108429,
        34.33983
      ],
      [
        31.108441,
        34.339825
      ],
      [
        31.130551,
        34.331819
      ],
      [
        31.147268,
        34.325792
      ],
      [
        31.14729,
        34.325784
      ],
      [
        31.178269,
        34.314417
      ],
      [
        31.178076,
        34.313974
      ],
      [
        31.187431,
        34.390071
      ],
      [
        31.188955,
        34.390301
      ],
      [
        31.208325,
        34.406644
      ],
      [
        31.208325,
        34.406644
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת שדרות דרום",
    "icao": "BDRTS",
    "timeOpened": 14,
    "low": 0,
    "high": 1500,
    "coords": [
      [
        31.344171,
        34.648183
      ],
      [
        31.321454,
        34.595964
      ],
      [
        31.320114,
        34.527972
      ],
      [
        31.36011,
        34.488164
      ],
      [
        31.406334,
        34.530678
      ],
      [
        31.465865,
        34.578372
      ],
      [
        31.514154,
        34.621811
      ],
      [
        31.531009,
        34.632017
      ],
      [
        31.54417,
        34.632246
      ],
      [
        31.55316,
        34.632912
      ],
      [
        31.55630499999999,
        34.665892
      ],
      [
        31.553311,
        34.689978
      ],
      [
        31.553996,
        34.755888
      ],
      [
        31.52109,
        34.771134
      ],
      [
        31.49219,
        34.773066
      ],
      [
        31.445679,
        34.758817
      ],
      [
        31.417985,
        34.741315
      ],
      [
        31.382023,
        34.712229
      ],
      [
        31.339242,
        34.695441
      ],
      [
        31.344171,
        34.648183
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת שדרות צפון",
    "icao": "BDRTN",
    "timeOpened": 12,
    "low": 0,
    "high": 1000,
    "coords": [
      [
        31.612985,
        34.594757
      ],
      [
        31.664954,
        34.609708
      ],
      [
        31.70621999999999,
        34.621146
      ],
      [
        31.667817,
        34.738523
      ],
      [
        31.634489,
        34.734
      ],
      [
        31.621207,
        34.748647
      ],
      [
        31.605746,
        34.749134
      ],
      [
        31.597166,
        34.75451
      ],
      [
        31.599664,
        34.763425
      ],
      [
        31.600056,
        34.768776
      ],
      [
        31.598152,
        34.771457
      ],
      [
        31.593576,
        34.774591
      ],
      [
        31.587848,
        34.773713
      ],
      [
        31.584417,
        34.776843
      ],
      [
        31.582706,
        34.780861
      ],
      [
        31.582904,
        34.78532
      ],
      [
        31.57931,
        34.805842
      ],
      [
        31.534814,
        34.794345
      ],
      [
        31.512649,
        34.78147
      ],
      [
        31.538015,
        34.768261
      ],
      [
        31.555376,
        34.762645
      ],
      [
        31.55745599999999,
        34.752162
      ],
      [
        31.557824,
        34.724223
      ],
      [
        31.55805000000001,
        34.707367
      ],
      [
        31.558738,
        34.687544
      ],
      [
        31.56013,
        34.668554
      ],
      [
        31.558825,
        34.64837
      ],
      [
        31.55813,
        34.635122
      ],
      [
        31.59319199999999,
        34.614022
      ],
      [
        31.612985,
        34.594757
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת שיטים",
    "icao": "BSTIM",
    "timeOpened": 6,
    "low": 0,
    "high": 2500,
    "coords": [
      [
        30.294072,
        35.126495
      ],
      [
        30.263718,
        35.127937
      ],
      [
        30.244381,
        35.123328
      ],
      [
        30.22066299999999,
        35.127009
      ],
      [
        30.180754,
        35.119169
      ],
      [
        30.150377,
        35.122009
      ],
      [
        30.119104,
        35.129729
      ],
      [
        30.059043,
        35.105412
      ],
      [
        30.034943,
        35.087878
      ],
      [
        30.045348,
        35.050734
      ],
      [
        30.056154,
        35.018878
      ],
      [
        30.109512,
        35.02066
      ],
      [
        30.153287,
        35.029065
      ],
      [
        30.196679,
        35.01847
      ],
      [
        30.221088,
        35.023637
      ],
      [
        30.272009,
        35.028438
      ],
      [
        30.284371,
        35.088542
      ],
      [
        30.294072,
        35.126495
      ],
      [
        30.294072,
        35.126495
      ]
    ],
    "closed": false
  },
  {
    "name": "בועת שלם",
    "icao": "BSHLM",
    "timeOpened": 12,
    "low": 0,
    "high": 3000,
    "coords": [
      [
        31.717037,
        35.333239
      ],
      [
        31.705024,
        35.43733
      ],
      [
        31.628977,
        35.396326
      ],
      [
        31.552137,
        35.375571
      ],
      [
        31.506767,
        35.369254
      ],
      [
        31.509193,
        35.348661
      ],
      [
        31.499232,
        35.299119
      ],
      [
        31.436018,
        35.21125
      ],
      [
        31.510669,
        35.220988
      ],
      [
        31.566064,
        35.204448
      ],
      [
        31.647987,
        35.303244
      ],
      [
        31.717833,
        35.333204
      ],
      [
        31.717037,
        35.333239
      ]
    ],
    "closed": false
  }
]