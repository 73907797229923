export const lsaConstantReportPoints = [
  {
    "name": "א'ת נס ציונה",
    "icao": "IANES",
    "latlng": [
      31.920451,
      34.794238
    ],
    "key": "IANES",
    "route": [
      {
        "icao": "SIRNI",
        "distance": 19,
        "alt": 800,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "אבליים",
    "icao": "EVLYM",
    "latlng": [
      32.82396899081474,
      35.15233174239837
    ],
    "key": "EVLYM",
    "route": [
      {
        "icao": "AHIUD",
        "distance": 56,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "MOVIL",
        "distance": 61,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "GILAM",
        "distance": 10,
        "alt": 700,
        "time": 0,
        "active": true
      },
      {
        "icao": "AAKKO",
        "distance": 62,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "BZVUL",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 68,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF1",
        "distance": 60,
        "alt": 1300,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "אור עקיבא",
    "icao": "ORAKV",
    "latlng": [
      32.508056,
      34.93
    ],
    "key": "ORAKV",
    "route": [
      {
        "icao": "FRDIS",
        "distance": 52,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 27,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "SDTYM",
        "distance": 25,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 24,
        "alt": 0,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARN1",
        "distance": 25,
        "alt": 0,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "אחיהוד",
    "icao": "AHIUD",
    "latlng": [
      32.91108197440796,
      35.18062685892994
    ],
    "key": "AHIUD",
    "route": [
      {
        "icao": "YASIF",
        "distance": 10,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "EVLYM",
        "distance": 56,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 77,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BKRML",
        "distance": 110,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "איילון",
    "icao": "AYLON",
    "latlng": [
      31.871667,
      34.943611
    ],
    "key": "AYLON",
    "route": [
      {
        "icao": "LTRUN",
        "distance": 30,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 35,
        "alt": 1200,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "איקאה",
    "icao": "IKKEA",
    "latlng": [
      31.948554077578,
      34.77038227515468
    ],
    "key": "IKKEA",
    "route": [
      {
        "icao": "MEHOL",
        "distance": 8,
        "alt": 400,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "אשל הנשיא",
    "icao": "NASIH",
    "latlng": [
      31.330278,
      34.698333
    ],
    "key": "NASIH",
    "route": [
      {
        "icao": "MINGV",
        "distance": 25,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "GILAT",
        "distance": 24,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLBS",
        "distance": 29,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 57,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 59,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZGOAL",
        "distance": 47,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "אשתאול",
    "icao": "ESTOL",
    "latlng": [
      31.77895116164914,
      35.01115060592312
    ],
    "key": "ESTOL",
    "route": [
      {
        "icao": "SHARG",
        "distance": 25,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSEMS",
        "distance": 21,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LTRUN",
        "distance": 38,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "בית אורן",
    "icao": "BOREN",
    "latlng": [
      32.71269478986308,
      34.97088003156566
    ],
    "key": "BOREN",
    "route": [
      {
        "icao": "FRDIS",
        "distance": 75,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "HOTRM",
        "distance": 29,
        "alt": 700,
        "time": 0,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 57,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "בית עובד",
    "icao": "BOVED",
    "latlng": [
      31.92010047455893,
      34.76979855524051
    ],
    "key": "BOVED",
    "route": [
      {
        "icao": "IANES",
        "distance": 12,
        "alt": 400,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בית קמה",
    "icao": "BKAMA",
    "latlng": [
      31.441667,
      34.765556
    ],
    "key": "BKAMA",
    "route": [
      {
        "icao": "MACHZ",
        "distance": 43,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "MINGV",
        "distance": 52,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZLHAV",
        "distance": 46,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLRU",
        "distance": 26,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 40,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 45,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSHEV",
        "distance": 63,
        "alt": 1200,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בית שמש",
    "icao": "BSEMS",
    "latlng": [
      31.75273049932681,
      34.98099666444924
    ],
    "key": "BSEMS",
    "route": [
      {
        "icao": "ESTOL",
        "distance": 21,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZHRYA",
        "distance": 40,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "NCSHN",
        "distance": 45,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בת גלים",
    "icao": "GALIM",
    "latlng": [
      32.84009318631126,
      34.98057572334438
    ],
    "key": "GALIM",
    "route": [
      {
        "icao": "DAROM",
        "distance": 42,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLHA",
        "distance": 35,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "גלעם",
    "icao": "GILAM",
    "latlng": [
      32.81920997021124,
      35.1317869493538
    ],
    "key": "GILAM",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 10,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLHA",
        "distance": 45,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "גן שמואל",
    "icao": "GSHML",
    "latlng": [
      32.44608857120853,
      34.95491574744565
    ],
    "key": "GSHML",
    "route": [
      {
        "icao": "PRHNA",
        "distance": 18,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "HADRA",
        "distance": 25,
        "alt": 700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 52,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 62,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "גפן",
    "icao": "GEFFN",
    "latlng": [
      31.743056,
      34.870278
    ],
    "key": "GEFFN",
    "route": [
      {
        "icao": "NAHEM",
        "distance": 25,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZHRYA",
        "distance": 47,
        "alt": 1200,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "האון",
    "icao": "HAONN",
    "latlng": [
      32.72668309555381,
      35.62543894763793
    ],
    "key": "HAONN",
    "route": [
      {
        "icao": "ENGEV",
        "distance": 34,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZEMAH",
        "distance": 25,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLFK",
        "distance": 58,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "הגושרים",
    "icao": "HGSRM",
    "latlng": [
      33.221628,
      35.630364
    ],
    "key": "HGSRM",
    "route": [
      {
        "icao": "GOMME",
        "distance": 48,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BASAN",
        "distance": 47,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "SNNIR",
        "distance": 28,
        "alt": 5000,
        "time": 6,
        "active": false
      },
      {
        "icao": "LLKS",
        "distance": 19,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "החותרים",
    "icao": "HOTRM",
    "latlng": [
      32.753332,
      34.936594
    ],
    "key": "HOTRM",
    "route": [
      {
        "icao": "DAROM",
        "distance": 26,
        "alt": 700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BOREN",
        "distance": 29,
        "alt": 400,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "המוביל",
    "icao": "MOVIL",
    "latlng": [
      32.759167,
      35.233333
    ],
    "key": "MOVIL",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 61,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "KKANA",
        "distance": 61,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 43,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF1",
        "distance": 77,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 82,
        "alt": 2200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "זכריה",
    "icao": "ZHRYA",
    "latlng": [
      31.69848348484878,
      34.94402227138069
    ],
    "key": "ZHRYA",
    "route": [
      {
        "icao": "BSEMS",
        "distance": 40,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "GEFFN",
        "distance": 47,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "GOVRN",
        "distance": 62,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "ירחיב",
    "icao": "YARHV",
    "latlng": [
      32.150278,
      34.970556
    ],
    "key": "YARHV",
    "route": [
      {
        "icao": "SSOMR",
        "distance": 29,
        "alt": 900,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZNYMN",
        "distance": 14,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "כפר כמא",
    "icao": "KKAMA",
    "latlng": [
      32.71848820905436,
      35.44289756749542
    ],
    "key": "KKAMA",
    "route": [
      {
        "icao": "ZEMAH",
        "distance": 76,
        "alt": 2000,
        "time": 0,
        "active": false
      },
      {
        "icao": "TAVOR",
        "distance": 21,
        "alt": 1700,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "כפר כנא",
    "icao": "KKANA",
    "latlng": [
      32.75866518269945,
      35.34548262322257
    ],
    "key": "KKANA",
    "route": [
      {
        "icao": "BNTOF",
        "distance": 46,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 33,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MOVIL",
        "distance": 61,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZGLNI",
        "distance": 33,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "כפר מנחם",
    "icao": "NAHEM",
    "latlng": [
      31.745278,
      34.822222
    ],
    "key": "NAHEM",
    "route": [
      {
        "icao": "NAHLA",
        "distance": 55,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "GEFFN",
        "distance": 25,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 110,
        "alt": 1200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "כפר תבור",
    "icao": "TAVOR",
    "latlng": [
      32.6901576189106,
      35.41773144985008
    ],
    "key": "TAVOR",
    "route": [
      {
        "icao": "ZGLNI",
        "distance": 50,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 79,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 47,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "KKAMA",
        "distance": 21,
        "alt": 2000,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "כרי דשא",
    "icao": "DESHE",
    "latlng": [
      32.86834920606173,
      35.53173939940014
    ],
    "key": "DESHE",
    "route": [
      {
        "icao": "ZBTZD",
        "distance": 56,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "MIGDL",
        "distance": 23,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZAMID",
        "distance": 26,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 128,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "להבות הבשן",
    "icao": "BASAN",
    "latlng": [
      33.14411,
      35.637522
    ],
    "key": "BASAN",
    "route": [
      {
        "icao": "HGSRM",
        "distance": 47,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZBTMS",
        "distance": 81,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLKS",
        "distance": 49,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHULA",
        "distance": 18,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "לטרון",
    "icao": "LTRUN",
    "latlng": [
      31.838611,
      34.986667
    ],
    "key": "LTRUN",
    "route": [
      {
        "icao": "SHARG",
        "distance": 21,
        "alt": 1800,
        "time": 0,
        "active": true
      },
      {
        "icao": "NCSHN",
        "distance": 39,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "AYLON",
        "distance": 30,
        "alt": 1600,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "לפיד",
    "icao": "LAPID",
    "latlng": [
      31.916389,
      35.026944
    ],
    "key": "LAPID",
    "route": [
      {
        "icao": "REUTE",
        "distance": 23,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "MCZVA",
        "distance": 34,
        "alt": 1300,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מאכר",
    "icao": "MACHR",
    "latlng": [
      32.93645308096028,
      35.15599710303871
    ],
    "key": "MACHR",
    "route": [
      {
        "icao": "TAALL",
        "distance": 3,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מבוא חורון",
    "icao": "HORON",
    "latlng": [
      31.850278,
      35.029167
    ],
    "key": "HORON",
    "route": [
      {
        "icao": "SHARG",
        "distance": 19,
        "alt": 1800,
        "time": 0,
        "active": true
      },
      {
        "icao": "REUTE",
        "distance": 17,
        "alt": 1300,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מגדל",
    "icao": "MIGDL",
    "latlng": [
      32.8353196703516,
      35.50884570162653
    ],
    "key": "MIGDL",
    "route": [
      {
        "icao": "DESHE",
        "distance": 23,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZGLNI",
        "distance": 63,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZEMAH",
        "distance": 90,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 114,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 52,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף אייל",
    "icao": "MEYAL",
    "latlng": [
      32.2075,
      34.962833
    ],
    "key": "MEYAL",
    "route": [
      {
        "icao": "ELYAU",
        "distance": 10,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "VINGT",
        "distance": 79,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 81,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AZRIL",
        "distance": 28,
        "alt": 500,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "מחלף גולני",
    "icao": "ZGLNI",
    "latlng": [
      32.77364922338266,
      35.40632866031954
    ],
    "key": "ZGLNI",
    "route": [
      {
        "icao": "MIGDL",
        "distance": 63,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "TAVOR",
        "distance": 50,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNTOF",
        "distance": 66,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 35,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "KKANA",
        "distance": 33,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף דרום",
    "icao": "DAROM",
    "latlng": [
      32.79364654275233,
      34.94191829592183
    ],
    "key": "DAROM",
    "route": [
      {
        "icao": "GALIM",
        "distance": 42,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "HOTRM",
        "distance": 26,
        "alt": 400,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף מאחז",
    "icao": "MACHZ",
    "latlng": [
      31.5129946403704,
      34.78204163237181
    ],
    "key": "MACHZ",
    "route": [
      {
        "icao": "MKGAT",
        "distance": 55,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "BKAMA",
        "distance": 43,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZPLGT",
        "distance": 72,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BDRTN",
        "distance": 80,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחלף נס ציונה",
    "icao": "MNSZN",
    "latlng": [
      31.939125,
      34.815399
    ],
    "key": "MNSZN",
    "route": [
      {
        "icao": "RSNIM",
        "distance": 10,
        "alt": 700,
        "time": 0,
        "active": true
      },
      {
        "icao": "NTAIM",
        "distance": 18,
        "alt": 1200,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "מחלף קרית גת",
    "icao": "MKGAT",
    "latlng": [
      31.59847103612187,
      34.81130568153864
    ],
    "key": "MKGAT",
    "route": [
      {
        "icao": "NAHLA",
        "distance": 35,
        "alt": 1500,
        "time": 0,
        "active": true
      },
      {
        "icao": "MACHZ",
        "distance": 55,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "GOVRN",
        "distance": 48,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "ZPLGT",
        "distance": 33,
        "alt": 1500,
        "time": 6,
        "active": true
      },
      {
        "icao": "BDRTN",
        "distance": 69,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BLHAV",
        "distance": 66,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מחצבה",
    "icao": "MCZVA",
    "latlng": [
      31.973333,
      35.0325
    ],
    "key": "MCZVA",
    "route": [
      {
        "icao": "LAPID",
        "distance": 34,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "SSOMR",
        "distance": 82,
        "alt": 1300,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מרחביה",
    "icao": "MRHVA",
    "latlng": [
      32.59561187088863,
      35.31249102455863
    ],
    "key": "MRHVA",
    "route": [
      {
        "icao": "TAVOR",
        "distance": 79,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "NIRYA",
        "distance": 38,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "ENHRD",
        "distance": 47,
        "alt": 1000,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLMG",
        "distance": 42,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGWB",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGWA",
        "distance": 67,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "משולש חולות",
    "icao": "MEHOL",
    "latlng": [
      31.960852,
      34.764067
    ],
    "key": "MEHOL",
    "route": [
      {
        "icao": "TYONA",
        "distance": 35,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLRS",
        "distance": 7,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "משמר הנגב",
    "icao": "MINGV",
    "latlng": [
      31.368889,
      34.712778
    ],
    "key": "MINGV",
    "route": [
      {
        "icao": "BKAMA",
        "distance": 52,
        "alt": 150,
        "time": 0,
        "active": true
      },
      {
        "icao": "NASIH",
        "distance": 25,
        "alt": 1200,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "נחלה",
    "icao": "NAHLA",
    "latlng": [
      31.656389,
      34.809167
    ],
    "key": "NAHLA",
    "route": [
      {
        "icao": "NAHEM",
        "distance": 55,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "MKGAT",
        "distance": 35,
        "alt": 1200,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "נטעים",
    "icao": "NTAIM",
    "latlng": [
      31.94361111111111,
      34.78083333333333
    ],
    "key": "NTAIM",
    "route": [
      {
        "icao": "SIRNI",
        "distance": 28,
        "alt": 800,
        "time": 0,
        "active": false
      },
      {
        "icao": "TYONA",
        "distance": 46,
        "alt": 1200,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "ניר אליהו",
    "icao": "ELYAU",
    "latlng": [
      32.192222,
      34.955278
    ],
    "key": "ELYAU",
    "route": [
      {
        "icao": "MEYAL",
        "distance": 10,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZNYMN",
        "distance": 12,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "ניר יפה",
    "icao": "NIRYA",
    "latlng": [
      32.566111,
      35.248889
    ],
    "key": "NIRYA",
    "route": [
      {
        "icao": "ZMGID",
        "distance": 30,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 38,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 91,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "נצר סירני",
    "icao": "SIRNI",
    "latlng": [
      31.92794550814241,
      34.83013120526801
    ],
    "key": "SIRNI",
    "route": [
      {
        "icao": "NSHRM",
        "distance": 35,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "MNSZN",
        "distance": 10,
        "alt": 700,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "נשרים",
    "icao": "NSHRM",
    "latlng": [
      31.909167,
      34.891667
    ],
    "key": "NSHRM",
    "route": [
      {
        "icao": "SIRNI",
        "distance": 35,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "SHLVM",
        "distance": 52,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "AYLON",
        "distance": 35,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "NCSHN",
        "distance": 58,
        "alt": 800,
        "time": 6,
        "active": true
      },
      {
        "icao": "LLNN",
        "distance": 16,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "NAHEM",
        "distance": 110,
        "alt": 800,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "עין גב",
    "icao": "ENGEV",
    "latlng": [
      32.781299,
      35.63944
    ],
    "key": "ENGEV",
    "route": [
      {
        "icao": "HAONN",
        "distance": 34,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZBTZD",
        "distance": 77,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "עירון",
    "icao": "EIRON",
    "latlng": [
      32.47997804982664,
      35.02392186015813
    ],
    "key": "EIRON",
    "route": [
      {
        "icao": "ARRAA",
        "distance": 24,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "PRHNA",
        "distance": 27,
        "alt": 700,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "עכו",
    "icao": "AAKKO",
    "latlng": [
      32.91692068829386,
      35.09544480479971
    ],
    "key": "AAKKO",
    "route": [
      {
        "icao": "EVLYM",
        "distance": 60,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLSO",
        "distance": 20,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BZVUL",
        "distance": 28,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "YASIF",
        "distance": 52,
        "alt": 2800,
        "time": 0,
        "active": false
      },
      {
        "icao": "SMRAT",
        "distance": 20,
        "alt": 400,
        "time": 0,
        "active": false
      }
    ]
  },
  {
    "name": "ערה",
    "icao": "ARRAA",
    "latlng": [
      32.49818280754972,
      35.06600571172022
    ],
    "key": "ARRAA",
    "route": [
      {
        "icao": "EIRON",
        "distance": 24,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZMGID",
        "distance": 80,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 110,
        "alt": 700,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "פרדס חנה",
    "icao": "PRHNA",
    "latlng": [
      32.47338713432443,
      34.97182648939537
    ],
    "key": "PRHNA",
    "route": [
      {
        "icao": "GSHML",
        "distance": 18,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "EIRON",
        "distance": 27,
        "alt": 2000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת בית המכס",
    "icao": "ZBTMS",
    "latlng": [
      33.0094129255682,
      35.6498148894138
    ],
    "key": "ZBTMS",
    "route": [
      {
        "icao": "ZBTZD",
        "distance": 64,
        "alt": 900,
        "time": 0,
        "active": true
      },
      {
        "icao": "BASAN",
        "distance": 81,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLIB",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHULA",
        "distance": 77,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BRGOL",
        "distance": 26,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "NAFCH",
        "distance": 52,
        "alt": 2800,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת בית ציידה",
    "icao": "ZBTZD",
    "latlng": [
      32.90205447539537,
      35.63244184738212
    ],
    "key": "ZBTZD",
    "route": [
      {
        "icao": "DESHE",
        "distance": 56,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "ENGEV",
        "distance": 77,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLIB",
        "distance": 56,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZBTMS",
        "distance": 64,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BKNRT",
        "distance": 35,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BRGOL",
        "distance": 82,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת גומא",
    "icao": "GOMME",
    "latlng": [
      33.167342,
      35.569163
    ],
    "key": "GOMME",
    "route": [
      {
        "icao": "ZKOCH",
        "distance": 26,
        "alt": 900,
        "time": 0,
        "active": true
      },
      {
        "icao": "HGSRM",
        "distance": 48,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLKS",
        "distance": 29,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת טל אל",
    "icao": "TAALL",
    "latlng": [
      32.931886,
      35.16029
    ],
    "key": "TAALL",
    "route": [
      {
        "icao": "YASIF",
        "distance": 12,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "MACHR",
        "distance": 3,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת יאסיף",
    "icao": "YASIF",
    "latlng": [
      32.911852,
      35.156408
    ],
    "key": "YASIF",
    "route": [
      {
        "icao": "TAALL",
        "distance": 12,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "AHIUD",
        "distance": 10,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "BZVUL",
        "distance": 19,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AAKKO",
        "distance": 43,
        "alt": 1000,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת כח",
    "icao": "ZKOCH",
    "latlng": [
      33.123833,
      35.568428
    ],
    "key": "ZKOCH",
    "route": [
      {
        "icao": "SELZR",
        "distance": 48,
        "alt": 900,
        "time": 0,
        "active": true
      },
      {
        "icao": "GOMME",
        "distance": 26,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHULA",
        "distance": 19,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת מגידו",
    "icao": "ZMGID",
    "latlng": [
      32.57171061299329,
      35.19087434664964
    ],
    "key": "ZMGID",
    "route": [
      {
        "icao": "ARRAA",
        "distance": 80,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "NIRYA",
        "distance": 30,
        "alt": 2000,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLMG",
        "distance": 27,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 119,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BARRA",
        "distance": 36,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "DALIA",
        "distance": 56,
        "alt": 1200,
        "time": 6,
        "active": false
      }
    ]
  },
  {
    "name": "צומת נווה ימין",
    "icao": "ZNYMN",
    "latlng": [
      32.172222,
      34.958611
    ],
    "key": "ZNYMN",
    "route": [
      {
        "icao": "YARHV",
        "distance": 14,
        "alt": 500,
        "time": 0,
        "active": true
      },
      {
        "icao": "ELYAU",
        "distance": 12,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת נחל חדרה",
    "icao": "HADRA",
    "latlng": [
      32.46329173247261,
      34.91111366132217
    ],
    "key": "HADRA",
    "route": [
      {
        "icao": "GSHML",
        "distance": 25,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "ORAKV",
        "distance": 27,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 55,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN1",
        "distance": 12,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת נחשון",
    "icao": "NCSHN",
    "latlng": [
      31.815278,
      34.916111
    ],
    "key": "NCSHN",
    "route": [
      {
        "icao": "NSHRM",
        "distance": 58,
        "alt": 1200,
        "time": 6,
        "active": true
      },
      {
        "icao": "LTRUN",
        "distance": 39,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSEMS",
        "distance": 45,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLNN",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת עמיעד",
    "icao": "ZAMID",
    "latlng": [
      32.91079292768978,
      35.54179940121615
    ],
    "key": "ZAMID",
    "route": [
      {
        "icao": "DESHE",
        "distance": 26,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLIB",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZKADR",
        "distance": 37,
        "alt": 1700,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צומת פורדיס",
    "icao": "FRDIS",
    "latlng": [
      32.59049768263315,
      34.9452915495751
    ],
    "key": "FRDIS",
    "route": [
      {
        "icao": "BOREN",
        "distance": 75,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ORAKV",
        "distance": 52,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "DOORS",
        "distance": 20,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "BOORN",
        "distance": 70,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSARN",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "CNHRL",
        "distance": 33,
        "alt": 1000,
        "time": 0,
        "active": true
      },
      {
        "icao": "BTSLM",
        "distance": 35,
        "alt": 1500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "צמח",
    "icao": "ZEMAH",
    "latlng": [
      32.70321725724381,
      35.58475213984033
    ],
    "key": "ZEMAH",
    "route": [
      {
        "icao": "MIGDL",
        "distance": 90,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "HAONN",
        "distance": 25,
        "alt": 800,
        "time": 0,
        "active": true
      },
      {
        "icao": "ENHRD",
        "distance": 136,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEA",
        "distance": 64,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BMGEB",
        "distance": 61,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "ראשונים",
    "icao": "RSNIM",
    "latlng": [
      31.9497173956512,
      34.80206921471677
    ],
    "key": "RSNIM",
    "route": [
      {
        "icao": "IKKEA",
        "distance": 15,
        "alt": 500,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "רעות",
    "icao": "REUTE",
    "latlng": [
      31.878333,
      35.024444
    ],
    "key": "REUTE",
    "route": [
      {
        "icao": "SHLVM",
        "distance": 21,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "LAPID",
        "distance": 23,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "HORON",
        "distance": 17,
        "alt": 1300,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שדה אליעזר",
    "icao": "SELZR",
    "latlng": [
      33.04390842135356,
      35.57052065127296
    ],
    "key": "SELZR",
    "route": [
      {
        "icao": "ZKOCH",
        "distance": 48,
        "alt": 1200,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLIB",
        "distance": 40,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שורק",
    "icao": "SOREK",
    "latlng": [
      31.94158240272018,
      34.75150138723555
    ],
    "key": "SOREK",
    "route": [
      {
        "icao": "MEHOL",
        "distance": 13,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "BOVED",
        "distance": 16,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZASHD",
        "distance": 74,
        "alt": 400,
        "time": 6,
        "active": true
      }
    ]
  },
  {
    "name": "שמרת",
    "icao": "SMRAT",
    "latlng": [
      32.95127454362478,
      35.08931474251613
    ],
    "key": "SMRAT",
    "route": [
      {
        "icao": "AAKKO",
        "distance": 20,
        "alt": 400,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שעלבים",
    "icao": "SHLVM",
    "latlng": [
      31.873333,
      34.983611
    ],
    "key": "SHLVM",
    "route": [
      {
        "icao": "REUTE",
        "distance": 21,
        "alt": 1300,
        "time": 0,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 52,
        "alt": 1000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שער הגיא",
    "icao": "SHARG",
    "latlng": [
      31.819722,
      35.021111
    ],
    "key": "SHARG",
    "route": [
      {
        "icao": "HORON",
        "distance": 19,
        "alt": 1800,
        "time": 0,
        "active": true
      },
      {
        "icao": "LTRUN",
        "distance": 21,
        "alt": 2300,
        "time": 0,
        "active": true
      },
      {
        "icao": "ESTOL",
        "distance": 25,
        "alt": 1700,
        "time": 0,
        "active": true
      },
      {
        "icao": "SORES",
        "distance": 31,
        "alt": 2800,
        "time": 6,
        "active": true
      },
      {
        "icao": "BGOVS",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שער שומרון",
    "icao": "SSOMR",
    "latlng": [
      32.106389,
      34.996111
    ],
    "key": "SSOMR",
    "route": [
      {
        "icao": "YARHV",
        "distance": 29,
        "alt": 900,
        "time": 0,
        "active": true
      },
      {
        "icao": "MCZVA",
        "distance": 82,
        "alt": 1000,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "שפיים",
    "icao": "SHFAM",
    "latlng": [
      32.21935,
      34.809659
    ],
    "key": "SHFAM",
    "route": [
      {
        "icao": "VINGT",
        "distance": 23,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "TYONA",
        "distance": 140,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "BSARS",
        "distance": 99,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "LLHZ",
        "distance": 32,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "תל יונה",
    "icao": "TYONA",
    "latlng": [
      32.00434031026089,
      34.72720122383612
    ],
    "key": "TYONA",
    "route": [
      {
        "icao": "SHEFC",
        "distance": 40,
        "alt": 400,
        "time": 6,
        "active": true
      },
      {
        "icao": "SHFAM",
        "distance": 140,
        "alt": 400,
        "time": 0,
        "active": true
      },
      {
        "icao": "NTAIM",
        "distance": 46,
        "alt": 800,
        "time": 0,
        "active": false
      }
    ]
  }
]