export const airstrips = [
  {
    "name": "אילת-רמון",
    "icao": "LLER",
    "direction": 1,
    "latlng": [
      29.72722222222222,
      35.01416666666667
    ],
    "key": "LLER",
    "closed": false,
    "route": [
      {
        "icao": "BRORA",
        "distance": 16,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SAMAR",
        "distance": 62,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "בן גוריון",
    "icao": "LLBG",
    "direction": 12,
    "latlng": [
      32.00884879344287,
      34.88517610461977
    ],
    "key": "LLBG",
    "closed": false,
    "route": [
      {
        "icao": "PARDS",
        "distance": 59,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRISN",
        "distance": 51,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "NSHRM",
        "distance": 60,
        "alt": 1200,
        "active": false
      },
      {
        "icao": "AYLON",
        "distance": 87,
        "alt": 2500,
        "active": false
      }
    ]
  },
  {
    "name": "הרצליה",
    "icao": "LLHZ",
    "direction": 11,
    "latlng": [
      32.18078254282661,
      34.83275219450232
    ],
    "key": "LLHZ",
    "closed": false,
    "route": [
      {
        "icao": "SFAIM",
        "distance": 22,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BAZRA",
        "distance": 33,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "GNYAM",
        "distance": 36,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SHFAM",
        "distance": 32,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "חיפה",
    "icao": "LLHA",
    "direction": 16,
    "latlng": [
      32.811995,
      35.043003
    ],
    "key": "LLHA",
    "closed": false,
    "route": [
      {
        "icao": "GALIM",
        "distance": 35,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "GILAM",
        "distance": 46,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "KRYON",
        "distance": 30,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HASID",
        "distance": 40,
        "alt": 1500,
        "active": false
      }
    ]
  },
  {
    "name": "מנחת אורים",
    "icao": "LLUR",
    "direction": 0,
    "latlng": [
      31.269407,
      34.537416
    ],
    "key": "LLUR",
    "closed": false,
    "route": [
      {
        "icao": "TZHOT",
        "distance": 26,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת אליעז",
    "icao": "LLHF",
    "direction": 9,
    "latlng": [
      32.535478,
      35.433929
    ],
    "key": "LLHF",
    "closed": false,
    "route": [
      {
        "icao": "ENHRD",
        "distance": 30,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BHROD",
        "distance": 3,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת גבולות",
    "icao": "LLGV",
    "direction": 5,
    "latlng": [
      31.22964,
      34.462983
    ],
    "key": "LLGV",
    "closed": false,
    "route": [
      {
        "icao": "NBSOR",
        "distance": 30,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BSVTA",
        "distance": 115,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת הבונים",
    "icao": "LLBO",
    "direction": 1,
    "latlng": [
      32.648487,
      34.932826
    ],
    "key": "LLBO",
    "closed": false,
    "route": [
      {
        "icao": "BOREN",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "FRDIS",
        "distance": 36,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת יטבתה",
    "icao": "LLYO",
    "direction": 2,
    "latlng": [
      29.9003003005455,
      35.06718600470229
    ],
    "key": "LLYO",
    "closed": false,
    "route": [
      {
        "icao": "YOTVT",
        "distance": 10,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת מגידו",
    "icao": "LLMG",
    "direction": 9,
    "latlng": [
      32.59705962772182,
      35.23448381986979
    ],
    "key": "LLMG",
    "closed": false,
    "route": [
      {
        "icao": "ZMGID",
        "distance": 27,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MRHVA",
        "distance": 42,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AFULA",
        "distance": 25,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת מצדה",
    "icao": "LLMZ",
    "direction": 1,
    "latlng": [
      31.325573,
      35.388145
    ],
    "key": "LLMZ",
    "closed": false,
    "route": [
      {
        "icao": "ZOHAR",
        "distance": 107,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ENGDI",
        "distance": 82,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "MMORR",
        "distance": 45,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BNGDI",
        "distance": 69,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BYMLH",
        "distance": 125,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת מצפה רמון",
    "icao": "LLMR",
    "direction": 1,
    "latlng": [
      30.655764,
      34.806867
    ],
    "key": "LLMR",
    "closed": false,
    "route": [
      {
        "icao": "RUHOT",
        "distance": 5,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת נען",
    "icao": "LLNN",
    "direction": 16,
    "latlng": [
      31.884102,
      34.886591
    ],
    "key": "LLNN",
    "closed": false,
    "route": [
      {
        "icao": "NSHRM",
        "distance": 16,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "NCSHN",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת סעד",
    "icao": "LLSS",
    "direction": 14,
    "latlng": [
      31.471282,
      34.559426
    ],
    "key": "LLSS",
    "closed": false,
    "route": [
      {
        "icao": "BDRTS",
        "distance": 65,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת עין יהב",
    "icao": "LLEY",
    "direction": 1,
    "latlng": [
      30.617783,
      35.202248
    ],
    "key": "LLEY",
    "closed": false,
    "route": [
      {
        "icao": "HAZVA",
        "distance": 95,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZOFAR",
        "distance": 37,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BYERU",
        "distance": 180,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת ערד",
    "icao": "LLAR",
    "direction": 8,
    "latlng": [
      31.23003615691965,
      35.19165674485323
    ],
    "key": "LLAR",
    "closed": false,
    "route": [
      {
        "icao": "ARRAD",
        "distance": 18,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "TARAD",
        "distance": 45,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת פיק",
    "icao": "LLFK",
    "direction": 4,
    "latlng": [
      32.788141,
      35.719596
    ],
    "key": "LLFK",
    "closed": false,
    "route": [
      {
        "icao": "HAONN",
        "distance": 58,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HAROV",
        "distance": 29,
        "alt": 3000,
        "active": false
      }
    ]
  },
  {
    "name": "מנחת קרית שמונה",
    "icao": "LLKS",
    "direction": 3,
    "latlng": [
      33.217413,
      35.597254
    ],
    "key": "LLKS",
    "closed": false,
    "route": [
      {
        "icao": "BASAN",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "GOMME",
        "distance": 29,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HGSRM",
        "distance": 19,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת ראשון",
    "icao": "LLRS",
    "direction": 0,
    "latlng": [
      31.968024,
      34.75361
    ],
    "key": "LLRS",
    "closed": false,
    "route": [
      {
        "icao": "TYONA",
        "distance": 26,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SOREK",
        "distance": 14,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת רוחמה",
    "icao": "LLRU",
    "direction": 6,
    "latlng": [
      31.462616,
      34.720945
    ],
    "key": "LLRU",
    "closed": false,
    "route": [
      {
        "icao": "BKAMA",
        "distance": 26,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BDRTS",
        "distance": 31,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת שמרת",
    "icao": "LLSO",
    "direction": 17,
    "latlng": [
      32.94477259732884,
      35.1040195063099
    ],
    "key": "LLSO",
    "closed": false,
    "route": [
      {
        "icao": "AAKKO",
        "distance": 17,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "BKRML",
        "distance": 150,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  },
  {
    "name": "מנחת תימן",
    "icao": "LLBS",
    "direction": 14,
    "latlng": [
      31.28740000000001,
      34.722085
    ],
    "key": "LLBS",
    "closed": false,
    "route": [
      {
        "icao": "ESHEL",
        "distance": 29,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "NCITY",
        "distance": 35,
        "alt": 0,
        "time": 0,
        "active": false
      },
      {
        "icao": "NASIH",
        "distance": 29,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "KUVSH",
        "distance": 27,
        "alt": 2000,
        "time": 5,
        "active": true
      }
    ]
  },
  {
    "name": "ראש פינה",
    "icao": "LLIB",
    "direction": 15,
    "latlng": [
      32.98083613846288,
      35.57200325278583
    ],
    "key": "LLIB",
    "closed": false,
    "route": [
      {
        "icao": "ZAMID",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZBTZD",
        "distance": 56,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "SELZR",
        "distance": 40,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZBTMS",
        "distance": 44,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "AMNON",
        "distance": 47,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "HULAT",
        "distance": 47,
        "alt": 0,
        "time": 0,
        "active": true
      },
      {
        "icao": "ZETIM",
        "distance": 50,
        "alt": 0,
        "time": 0,
        "active": true
      }
    ]
  }
]